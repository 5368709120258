import styled from "@emotion/styled";
import { Button } from "../Button/Button";

type ButtonGroupProps = {
    $styleType: "horizontal" | "vertical";
};

export const ButtonGroupWrapper = styled.div<ButtonGroupProps>`
    display: flex;
    gap: 0;

    flex-direction: ${({ $styleType }) => ($styleType === "horizontal" ? "row" : "column")};
`;

export const GroupButton = styled(Button)<ButtonGroupProps>`
    padding-left: 30px;
    padding-right: 30px;
    font-size: 18px;
    font-weight: normal;
    width: 100%;

    ${({ $styleType }) =>
        $styleType === "horizontal"
            ? `
        flex: 1;
        `
            : `
        border-radius: 10px;
        `}

    &:first-child:not(:only-child) {
        ${({ $styleType }) =>
            $styleType === "horizontal"
                ? `
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 1px;
        `
                : `
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-width: 1px;
        `}
    }

    &:last-child:not(:only-child) {
        ${({ $styleType }) =>
            $styleType === "horizontal"
                ? `
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-width: 1px;
        `
                : `
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top-width: 1px;
        `}
    }

    &:not(:first-child):not(:last-child) {
        ${({ $styleType }) =>
            $styleType === "horizontal"
                ? `
        border-radius: 0;
        border-right-width: 1px;
        border-left-width: 1px;
        `
                : `
        border-radius: 0;
        border-top-width: 1px;
        border-bottom-width: 1px;
        `}
    }
`;


import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { TOKEN_NAME } from "constants/common";
import { LoadingState } from "entities/Redux";
import { FetchStatus } from "entities/Enums";
import { UserProfileState, UserState } from "./types/userTypes";

const initialState: UserState = {
    token: "",
    profile: {
        loading: FetchStatus.None,
        error: "",
        data: {
            tosAreAgreed: false,
            registered: false,
            phone: "",
            firstName: "",
            lastName: "",
        },
    },
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            Cookies.set(TOKEN_NAME, action.payload, { secure: true, expires: 30 });
            state.token = action.payload;
        },
        setUserProfileLoading: (state, action: PayloadAction<LoadingState>) => {
            state.profile.loading = action.payload.loading;
            if (action.payload.error !== undefined) state.profile.error = action.payload.error;
        },
        setUserProfile: (state, action: PayloadAction<UserProfileState>) => {
            state.profile.data = action.payload;
        },
        clearProfile: (state) => {
            state.profile = initialState.profile;
        },
        logout: () => {
            Cookies.remove(TOKEN_NAME);
            return initialState;
        },

        // saga reducers

        fetchUserProfileSaga: () => {},
    },
});

export const { setToken, setUserProfileLoading, setUserProfile, clearProfile, logout } = userSlice.actions;

export const { fetchUserProfileSaga } = userSlice.actions;

export const userReducer = userSlice.reducer;


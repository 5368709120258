import { Header2 } from "assets/styles/global.styled";
import { Modal } from "components/ui/Modal";
import { useEffect, useState } from "react";
import {
    CalendarCell,
    CalendarDay,
    CalendarHeaderRow,
    CalendarRow,
    DateSlot,
    DoctorDefaultImage,
    DoctorExperience,
    DoctorImage,
    DoctorInfoWrapper,
    DoctorItemContainer,
    ModalDoctorItemContainer,
    DoctorName,
    DoctorPrice,
    DoctorSpecialization,
    HorizontalRule,
    ScheduleCalendar,
    ScheduleTitle,
    ScheduleWrapper,
    SelectedDate,
    SelectedDateSlots,
    SelectedDayWrapper,
    InfoParagraph,
    ModalDoctorImage,
    ModalDoctorInfoWrapper,
    ModalDoctorName,
    ModalDoctorPrice,
    ModalDoctorSpecialization,
    ModalInfoWrapper,
    FormContainer,
    ButtonSubmitForm,
    InputContainer,
} from "./DoctorItem.styled";
import { ReactComponent as CalendarSvg } from "assets/images/calendar.svg";
import { ReactComponent as LocationSvg } from "assets/images/location.svg";
import { formatDate, formatWeekday } from "utils/datetime";
import { AppointmentType, FetchStatus } from "entities/Enums";
import { ControllerLineText } from "components/common/Forms/controllers/ControllerLineText";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/store";
import { DoctorItemViewState } from "stores/types/doctorsTypes";
import { useNavigate } from "react-router";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { URL_AUTH, URL_MAIN_PAGE } from "constants/url";
import { bookAppointmentSaga, setBookFormLoadingState } from "stores/doctorsSlice";

type DoctorItemProps = {
    doctor: DoctorItemViewState;
};

type CalendarSlotType = {
    timestamp: number;
    timeString: string;
};

type CalendarDayType = {
    date: Date;
    dateString: string;
    slots: CalendarSlotType[];
};

type AppointmentInformationType = {
    address: string;
};

export const DoctorItem = (props: DoctorItemProps): React.ReactElement => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const { doctor } = props;
    const { token } = useSelector((state: RootState) => state.user);
    const { bookForm } = useSelector((state: RootState) => state.doctors);
    const { appointments } = useSelector((state: RootState) => state.appointments);

    const [nowDate, setNowDate] = useState(new Date());
    const [headerDays, setHeaderDays] = useState<string[]>([]);
    const [days, setDays] = useState<CalendarDayType[]>([]);
    const [selectedDay, setSelectedDay] = useState<number | undefined>();
    const [selectedSlot, setSelectedSlot] = useState<CalendarSlotType | undefined>();
    const [modalOpened, setModalOpened] = useState(false);

    useEffect(() => {
        const weekdays = ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];
        const nowWeekday = nowDate.getDay();
        setHeaderDays(weekdays.slice(nowWeekday).concat(weekdays.slice(0, nowWeekday)));

        const nextDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() + 1);
        const diffMs = nextDate.getTime() - nowDate.getTime();
        setTimeout(() => {
            // todo: fix when pc is sleeping
            setNowDate(new Date());
        }, diffMs);
    }, [nowDate]);

    function capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    useEffect(() => {
        const _days = Array.from(Array(14).keys()).map<CalendarDayType>((x, i) => {
            const date = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() + i);
            const startMs = date.getTime();
            const endMs = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() + i + 1).getTime();

            const dateString = capitalizeFirstLetter(date.toLocaleDateString("ru-RU", { weekday: "long", month: "long", day: "numeric" }));

            const slots = doctor.schedule.intervals
                .filter((x) => x.startDatetime >= startMs && x.startDatetime < endMs)
                .map<CalendarSlotType>((x) => {
                    const time = new Date(x.startDatetime);
                    const hoursStr = ("0" + time.getHours()).slice(-2);
                    const minStr = ("0" + time.getMinutes()).slice(-2);
                    return {
                        timestamp: x.startDatetime,
                        timeString: `${hoursStr}:${minStr}`,
                    };
                });

            return { date, slots, dateString };
        });

        setDays(_days);
    }, [nowDate, doctor.schedule.intervals]);

    const handleClickDate = (index: number) => {
        setSelectedDay(index);
    };

    const handleCloseModal = () => {
        setBookFormLoadingState({ loading: FetchStatus.None });
        setModalOpened(false);
        setSelectedSlot(undefined);
    };

    const navigateToAuth = () => {
        const next = `${URL_MAIN_PAGE}?${searchParams.toString()}`;
        const authParams = createSearchParams({ next: next } as any);

        navigate({
            pathname: URL_AUTH,
            search: `?${authParams}`,
        });
    };

    const handleClickDateSlot = (slot: CalendarSlotType) => {
        if (!token) {
            navigateToAuth();
        } else if (appointments.length > 0) {
            const currentParams = Object.fromEntries(searchParams.entries());
            const params = new URLSearchParams(currentParams);
            params.set('err', 'has_appointment');
            setSearchParams(params);
            window.location.reload();
        } else {
            setModalOpened(true);
            setSelectedSlot(slot);
        }
    };

    const { handleSubmit, watch, reset, control } = useForm<AppointmentInformationType>({
        mode: "onChange",
    });

    const { address } = watch();

    const onSubmit = (data: AppointmentInformationType) => {
        if (!selectedSlot) return;

        if (token) {
            if (bookForm.loading !== FetchStatus.Complete) {
                dispatch(
                    bookAppointmentSaga({
                        scheduleId: doctor.schedule.id,
                        startTimestamp: selectedSlot.timestamp,
                        address: data.address ? data.address : undefined,
                    })
                );
            }
        } else {
            navigateToAuth();
        }
    };

    const filled = () => {
        return !bookForm.error && (doctor.schedule.appointmentType !== AppointmentType.Outside || !!address);
    };

    return (
        <DoctorItemContainer>
            <DoctorInfoWrapper>
                {!!doctor.photo ? <DoctorImage src={doctor.photo}></DoctorImage> : <DoctorDefaultImage />}
                <div>
                    <DoctorSpecialization>{doctor.specialization.title}</DoctorSpecialization>
                    <DoctorName>{doctor.name}</DoctorName>
                    <DoctorExperience>Стаж {doctor.workPeriod} лет</DoctorExperience>
                    <DoctorPrice>{doctor.schedule.price === 0 ? "Бесплатно" : doctor.schedule.price + " ₽"}</DoctorPrice>
                </div>
            </DoctorInfoWrapper>

            <HorizontalRule></HorizontalRule>

            <ScheduleWrapper>
                <ScheduleTitle>Выберите дату и время для записи</ScheduleTitle>
                <ScheduleCalendar>
                    <CalendarHeaderRow>
                        {headerDays.map((x, i) => (
                            <CalendarCell key={i}>{x}</CalendarCell>
                        ))}
                    </CalendarHeaderRow>
                    <CalendarRow>
                        {days.slice(0, 7).map((d, i) => {
                            return (
                                <CalendarDay
                                    key={i}
                                    typeStyled="secondary"
                                    disabled={!d.slots.length}
                                    selected={selectedDay === i}
                                    onClick={() => handleClickDate(i)}
                                >
                                    {d.date.getDate()}
                                </CalendarDay>
                            );
                        })}
                    </CalendarRow>
                    <CalendarRow>
                        {days.slice(7).map((d, i) => {
                            const ind = 7 + i;
                            return (
                                <CalendarDay
                                    key={7 + ind}
                                    typeStyled="secondary"
                                    selected={selectedDay === ind}
                                    disabled={!d.slots.length}
                                    onClick={() => handleClickDate(ind)}
                                >
                                    {d.date.getDate()}
                                </CalendarDay>
                            );
                        })}
                    </CalendarRow>
                </ScheduleCalendar>

                {selectedDay !== undefined && !!days.length && (
                    <SelectedDayWrapper>
                        <SelectedDate>{days[selectedDay].dateString}</SelectedDate>
                        <SelectedDateSlots>
                            {days[selectedDay].slots.map((s, i) => (
                                <DateSlot key={i} typeStyled="secondary" onClick={() => handleClickDateSlot(s)}>
                                    {s.timeString}
                                </DateSlot>
                            ))}
                        </SelectedDateSlots>
                    </SelectedDayWrapper>
                )}

                {modalOpened && !!selectedSlot && (
                    <Modal onClose={handleCloseModal}>
                        {doctor.schedule.appointmentType === AppointmentType.Online && (
                            <>
                                <Header2>Запись на онлайн прием</Header2>
                                <InfoParagraph>
                                    {doctor.schedule.price === 0
                                        ? ""
                                        : "После оплаты вам будет предоставлена ссылка для подключения в онлайн кабинет врача."}
                                </InfoParagraph>
                            </>
                        )}

                        {doctor.schedule.appointmentType === AppointmentType.Outside && <Header2>Вызов врача на дом</Header2>}

                        {doctor.schedule.appointmentType === AppointmentType.Inside && <Header2>Запись в клинику</Header2>}

                        <ModalDoctorItemContainer>
                            <ModalDoctorInfoWrapper>
                                {!!doctor.photo ? <ModalDoctorImage src={doctor.photo}></ModalDoctorImage> : <DoctorDefaultImage />}
                                <div>
                                    <ModalDoctorName>{doctor.name}</ModalDoctorName>
                                    <ModalDoctorSpecialization>{doctor.specialization.title}</ModalDoctorSpecialization>
                                    <ModalDoctorPrice>
                                        {doctor.schedule.price === 0 ? "Бесплатно" : doctor.schedule.price + " ₽"}
                                    </ModalDoctorPrice>
                                </div>
                            </ModalDoctorInfoWrapper>

                            <ModalInfoWrapper>
                                <CalendarSvg />
                                <p>
                                    {formatWeekday(selectedSlot.timestamp)}, {formatDate(selectedSlot.timestamp)}
                                </p>
                            </ModalInfoWrapper>

                            {doctor.schedule.appointmentType === AppointmentType.Inside && (
                                <ModalInfoWrapper>
                                    <LocationSvg />
                                    <p>{doctor.schedule.branch?.address}</p>
                                </ModalInfoWrapper>
                            )}
                        </ModalDoctorItemContainer>

                        <FormContainer onSubmit={handleSubmit(onSubmit)}>
                            {doctor.schedule.appointmentType === AppointmentType.Outside && (
                                <InputContainer>
                                    <ControllerLineText
                                        name="address"
                                        control={control}
                                        label="Адрес вызова"
                                        autoFocus={true}
                                        loading={bookForm.loading}
                                    />
                                </InputContainer>
                            )}

                            <ButtonSubmitForm type="submit" loading={bookForm.loading} disabled={!filled()}>
                                Записаться
                            </ButtonSubmitForm>
                        </FormContainer>
                    </Modal>
                )}
            </ScheduleWrapper>
        </DoctorItemContainer>
    );
};


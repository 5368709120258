import { CLINIC_PHONE, CLINIC_PHONE_URL } from "constants/customInfo";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/store";
import { NavbarContainer, NavbarLink, NavbarResponseContainer, NavbarRightAlign, PhoneLink, PhoneLinkText } from "./MainNavbar.styles";
import { ReactComponent as PhoneSvg } from "assets/images/phone.svg";
import { ReactComponent as LogoutSvg } from "assets/images/logout.svg";
import { URL_AUTH } from "constants/url";
import { logout } from "stores/userSlice";
import { useLocation } from "react-router";

const MainNavbarContainer: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { token, profile } = useSelector((state: RootState) => state.user);

    const handleClickLogout = () => {
        dispatch(logout());
    };

    return (
        <NavbarContainer>
            <NavbarResponseContainer>
                <PhoneLink title={CLINIC_PHONE} url={CLINIC_PHONE_URL} icon={<PhoneSvg />} type="outside">
                    <PhoneLinkText>{CLINIC_PHONE}</PhoneLinkText>
                </PhoneLink>

                {!token && <NavbarLink title="Войти" url={URL_AUTH} />}
                {!!token && (
                    <NavbarRightAlign>
                        <span>
                            {profile.data.firstName} {profile.data.lastName}
                        </span>
                        <NavbarLink
                            title=""
                            url={`${location.pathname}${location.search}`}
                            icon={<LogoutSvg />}
                            onClick={handleClickLogout}
                        />
                    </NavbarRightAlign>
                )}
            </NavbarResponseContainer>
        </NavbarContainer>
    );
};

export const MainNavbar: React.FC = memo(MainNavbarContainer);


import React, { InputHTMLAttributes, memo, useMemo, useState, useRef, LegacyRef } from "react";

import { useOutsideClick } from "hooks/useOutsideClick";
import { FetchStatus } from "entities/Enums";

import { InputWrapper, StyledInput, StyledInputMask, ErrorMessage, InputWithIconWrapper, FloatingLabel, IconWrapper } from "./Input.styled";

type InputType = {
    icon?: React.ReactElement;
    className?: string;
    loading?: FetchStatus;
    error?: string;
    label?: string;
    placeholder?: string;
    centerText?: boolean;
    showError?: boolean;
    mask?: string;
    autoComplete?: string;
    refInput?: LegacyRef<HTMLInputElement>;
    onChangeText?: (value: string) => void;
    onFocus?: () => void;
    onIconClick?: () => void;
} & InputHTMLAttributes<HTMLInputElement>;

const InputContainer = (props: InputType) => {
    const {
        className,
        value,
        label,
        placeholder,
        type,
        mask,
        icon,
        maxLength,
        autoComplete,
        autoFocus,
        refInput,
        loading = FetchStatus.None,
        disabled,
        readOnly = false,
        centerText = false,
        error = "",
        showError = true,
        onChangeText,
        onFocus,
        onBlur,
        onIconClick,
        ...others
    } = props;
    const ref = useRef(null);
    const [focused, setFocused] = useState(!!autoFocus);

    const isLoading = useMemo(() => {
        return loading === FetchStatus.Fetching;
    }, [loading]);

    const handleChange = (value: string) => {
        if (onChangeText) {
            onChangeText(value);
        }
    };

    const handleFocus = () => {
        if (onFocus) {
            onFocus();
        }
        setFocused(true);
    };

    useOutsideClick(ref, () => setFocused(false), focused);

    return (
        <InputWrapper className={className}>
            <InputWithIconWrapper ref={ref}>
                {mask ? (
                    <StyledInputMask
                        {...others}
                        value={value}
                        type={type}
                        disabled={isLoading || disabled}
                        autoComplete={autoComplete}
                        maxLength={maxLength}
                        autoFocus={autoFocus}
                        readOnly={readOnly}
                        onFocus={handleFocus}
                        onBlur={onBlur}
                        onAccept={(value) => handleChange(value as string)}
                        mask={mask}
                        radix="."
                        placeholderChar="X"
                        $loading={loading ? true : false}
                        $error={error}
                        $centerText={centerText}
                        $withIcon={!!icon}
                        placeholder={!label || (label && focused) ? placeholder : ""}
                    />
                ) : (
                    <StyledInput
                        {...others}
                        value={value}
                        type={type}
                        disabled={isLoading || disabled}
                        autoComplete={autoComplete}
                        maxLength={maxLength}
                        autoFocus={autoFocus}
                        ref={refInput}
                        readOnly={readOnly}
                        onFocus={handleFocus}
                        onBlur={onBlur}
                        onChange={(e) => handleChange(e.target.value)}
                        $loading={isLoading}
                        $error={error}
                        $centerText={centerText}
                        $withIcon={!!icon}
                        placeholder={!label || (label && focused) ? placeholder : ""}
                    />
                )}

                {label && (
                    <FloatingLabel active={(focused && !readOnly) || !!value} centerText={centerText} focused={focused}>
                        {label}
                    </FloatingLabel>
                )}

                {!!icon && !isLoading && <IconWrapper onClick={onIconClick}>{icon}</IconWrapper>}
            </InputWithIconWrapper>

            {error && showError && <ErrorMessage $centerText={centerText}>{error}</ErrorMessage>}
        </InputWrapper>
    );
};

export const Input = memo(InputContainer);


/** состояние запроса получения счетчиков динамического фильтра */
export enum FetchStatus {
    None,
    Fetching,
    Complete,
    Fail,
}

export enum AppointmentType {
    Online = "online",
    Inside = "inside",
    Outside = "outside",
}

export enum DoctorFiltrationType {
    Front = "front",
    Back = "back",
}

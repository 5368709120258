/*
 * Url для страниц
 * */

export const URL_MAIN_WEBSITE = "";

export const URL_MAIN_PAGE = "/";
export const URL_AUTH = "/auth";
export const URL_TERMS = "/terms";
export const URL_PRIVACY = "/privacy";


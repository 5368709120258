import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { FetchStatus } from "entities/Enums";
import {
    BookAppointmentProps,
    DoctorListCacheState,
    DoctorListFilter,
    DoctorListViewState,
    DoctorsState,
    SpecializationState,
    BranchState,
} from "./types/doctorsTypes";
import { LoadingState } from "entities/Redux";

const initialState: DoctorsState = {
    doctorsCache: {
        count: 0,
        results: [],
    },
    doctors: {
        loading: FetchStatus.None,
        error: "",
        data: {
            count: 0,
            results: [],
        },
    },
    specializations: {
        loading: FetchStatus.None,
        error: "",
        data: [],
    },
    branches: {
        loading: FetchStatus.None,
        error: "",
        data: [],
    },
    bookForm: {
        loading: FetchStatus.None,
        error: "",
    },
};

export const doctorsSlice = createSlice({
    name: "doctors",
    initialState,
    reducers: {
        setSpecializations: (state, action: PayloadAction<SpecializationState[]>) => {
            state.specializations.data = action.payload;
        },
        setSpecializationsLoadingState: (state, action: PayloadAction<LoadingState>) => {
            state.specializations.loading = action.payload.loading;
            state.specializations.error = !!action.payload.error ? action.payload.error : "";
        },
        setBranches: (state, action: PayloadAction<BranchState[]>) => {
            state.branches.data = action.payload;
        },
        setBranchesLoadingState: (state, action: PayloadAction<LoadingState>) => {
            state.branches.loading = action.payload.loading;
            state.branches.error = !!action.payload.error ? action.payload.error : "";
        },
        setDoctorListCache: (state, action: PayloadAction<DoctorListCacheState>) => {
            state.doctorsCache = action.payload;
        },
        setDoctorList: (state, action: PayloadAction<DoctorListViewState>) => {
            state.doctors.data = action.payload;
        },
        setDoctorListLoadingState: (state, action: PayloadAction<LoadingState>) => {
            state.doctors.loading = action.payload.loading;
            state.doctors.error = !!action.payload.error ? action.payload.error : "";
        },
        addDoctorsToList: (state, action: PayloadAction<DoctorListViewState>) => {
            state.doctors.data.count += action.payload.count;
            state.doctors.data.results = [...state.doctors.data.results, ...action.payload.results];
        },
        clearDoctorListState: (state) => {
            state.doctors = initialState.doctors;
        },
        setBookFormLoadingState: (state, action: PayloadAction<LoadingState>) => {
            state.bookForm.loading = action.payload.loading;
            state.bookForm.error = action.payload.error ? action.payload.error : "";
        },

        // saga reducers

        fetchSpecializationsSaga: (state) => {},
        fetchBranchesSaga: (state) => {},
        fetchDoctorListSaga: (state, action: PayloadAction<{ filter: DoctorListFilter; forceUpdateCache: boolean }>) => {},
        bookAppointmentSaga: (state, action: PayloadAction<BookAppointmentProps>) => {},
    },
});

export const {
    setSpecializations,
    setSpecializationsLoadingState,
    setBranches,
    setBranchesLoadingState,
    setDoctorListCache,
    setDoctorList,
    setDoctorListLoadingState,
    addDoctorsToList,
    clearDoctorListState,
    setBookFormLoadingState,
} = doctorsSlice.actions;

export const { fetchSpecializationsSaga, fetchBranchesSaga, fetchDoctorListSaga, bookAppointmentSaga } = doctorsSlice.actions;

export const doctorsReducer = doctorsSlice.reducer;


import styled from "@emotion/styled";
import { NavLink, Link } from "react-router-dom";
import { css, Theme } from "@emotion/react";

export const transientOptions = {
    shouldForwardProp: (propName: string) => !propName.startsWith("$"),
};

const styleForLink = ({ theme, $hasIcon }: Theme & { $hasIcon: boolean }) => css`
    text-decoration: none;
    transition: color ${theme.global.transition};
    color: ${theme.global.link.font.color};

    ${$hasIcon &&
    `
        display: inline-flex;
        align-items: center;
    `}

    svg {
        fill: ${theme.global.link.font.color};
    }

    &:hover {
        color: ${theme.global.link.font.hoverColor};

        svg {
            fill: ${theme.global.link.font.hoverColor};
        }
    }

    &:focus-visible {
        color: ${theme.global.link.font.focusColor};

        svg {
            fill: ${theme.global.link.font.focusColor};
        }
    }

    &:active,
    &.active {
        color: ${theme.global.link.font.activeColor};

        svg {
            fill: ${theme.global.link.font.activeColor};
        }
    }
`;

export const LinkStyledDefault = styled("a", transientOptions)`
    ${styleForLink};
`;

export const NavLinkStyled = styled(NavLink, transientOptions)`
    ${styleForLink}
`;

export const LinkStyled = styled(Link, transientOptions)`
    ${styleForLink}
`;

export const LinkIcon = styled.span`
    margin-right: 10px;
    width: 27px;
    height: 27px;

    svg {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }
`;


import styled from "@emotion/styled";
import { ReactComponent as NotificationSvg } from "assets/images/appointment-notification.svg";
import { Link } from "components/ui/Link";
import { ElementContainer } from "components/ui/ElementContainer";
import { Button } from "components/ui/Button";

type NotificationProps = {
    $isError?: boolean;
};

type MenuFloatWrapperProps = {
    $opened: boolean;
};

type MenuFloatItemProps = {};

export const transientOptions = {
    shouldForwardProp: (propName: string) => !propName.startsWith("$"),
};

export const AppointmentContainer = styled(ElementContainer)``;

export const HeadWrapper = styled.div`
    position: relative;
    display: flex;
    gap: 20px;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        gap: 15px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        flex-direction: column;
    }

    & > * {
        flex: 1;
    }
`;

export const ContentWrapper = styled.div``;

export const InfoWrapper = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    min-width: 300px;

    svg {
        fill: #9d9faf;
        width: 34px;
        height: 34px;
        min-width: 34px;
        margin: 0 13px;

        @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
            margin: 0 6px;
        }
    }
`;

export const MenuWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;

    svg {
        fill: #9d9faf;
        width: 34px;
        height: 34px;
        min-width: 34px;
        cursor: pointer;

        &:hover {
            fill: #2c41ff;
        }
    }
`;

export const MenuFloatWrapper = styled("div", transientOptions)<MenuFloatWrapperProps>`
    display: flex;
    flex-diretion: column;
    gap: 0;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
    transition: ${(props) => props.theme.global.transition};
    ${({ $opened }) => (!$opened ? "visibility: hidden; opacity: 0;" : "")}
`;

export const MenuFloatItem = styled("div", transientOptions)<MenuFloatItemProps>`
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 3px;
    transition: ${(props) => props.theme.transition};

    &:hover {
        background-color: #eaeaea;
    }
`;

export const FormContainer = styled.form``;

export const ButtonSubmitForm = styled(Button)`
    width: 100%;
    margin-top: 20px;
`;

export const DoctorInfoWrapper = styled(InfoWrapper)`
    align-items: flex-start;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        min-width: auto;
        width: calc(100% - 34px);
    }
`;

export const DoctorInfo = styled.div``;

export const DoctorImage = styled.img`
    width: 60px;
    height: 60px;
    min-width: 60px;
    border-radius: 50%;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        width: 45px;
        height: 45px;
        min-width: 45px;
    }
`;

export const DoctorDefaultImage = styled.div`
    width: 60px;
    height: 60px;
    min-width: 60px;
    background: #f6f6f6;
    border-radius: 50%;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        width: 45px;
        height: 45px;
        min-width: 45px;
    }
`;

export const DoctorName = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`;

export const DoctorSpecialization = styled.div`
    font-size: 18px;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        font-size: 16px;
    }
`;

export const NotificationContainer = styled("div", transientOptions)<NotificationProps>`
    background-color: ${({ $isError }) => ($isError ? "#FFEBEE" : "#F6F6F6")};
    border-radius: 10px;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        padding: 15px;
    }

    ${({ $isError }) =>
        $isError &&
        `
    svg {
      fill: #EF5350;
    }
  `}
`;

export const NotificationIcon = styled(NotificationSvg)`
    width: 34px;
    height: 34px;
    min-width: 34px;
    margin-right: 20px;
    fill: #2c41ff;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 22px;
        height: 22px;
        min-width: 22px;
        margin-right: 10px;
    }
`;

export const NotificationContent = styled.span`
    width: fit-content;
    padding-top: 4px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        padding-top: 0;
    }
`;

export const MeetingLinkWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;
    margin-top: 30px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-top: 20px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        margin-top: 15px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletL}) {
        flex-direction: column;
        gap: 20px;
    }
`;

export const CopyLink = styled(Link)`
    font-weight: bold;
`;

export const ButtonTextMobileCorrection = styled.span`
    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        display: none;
    }
`;

export const AppointmentHelperText = styled.div`
    margin-top: 35px;

    @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
        margin-top: 20px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-top: 15px;
    }
`;


import { memo, useEffect } from "react";
import { ResponseContainerForm, TitleForm, SubtitleForm, ButtonSubmitForm, FormContainer, ErrorField } from "../Forms.styled";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ControllerPhone } from "../controllers/ControllerPhone";
import { FormStateType } from "entities/Forms";
import { RootState } from "stores/store";
import { sendPhoneSaga, setAuthorizationLoadingState } from "stores/authorizationSlice";
import { FetchStatus } from "entities/Enums";

import { setFieldErrorMessage } from "stores/authorizationSlice";

const FormSendPhoneContainer = () => {
    const dispatch = useDispatch();
    const { phone, loading, error, fieldErrors } = useSelector((state: RootState) => state.authorization);

    const { handleSubmit, control } = useForm<Pick<FormStateType, "phone">>({
        defaultValues: {
            phone,
        },
    });

    const onSubmit = (data: Pick<FormStateType, "phone">) => {
        dispatch(setFieldErrorMessage({}));
        dispatch(sendPhoneSaga({ phone: data.phone }));
    };

    useEffect(() => {
        dispatch(setAuthorizationLoadingState({ loading: FetchStatus.None }));
    }, []);

    return (
        <ResponseContainerForm>
            <TitleForm>Авторизация</TitleForm>
            <SubtitleForm>Войдите, чтобы продолжить</SubtitleForm>

            <FormContainer onSubmit={handleSubmit(onSubmit)}>
                <ControllerPhone name="phone" control={control} loading={loading} error={fieldErrors?.phone} />
                <ErrorField>{error}</ErrorField>

                <ButtonSubmitForm type="submit" loading={loading}>
                    Продолжить
                </ButtonSubmitForm>
            </FormContainer>
        </ResponseContainerForm>
    );
};

export const FormSendPhone = memo(FormSendPhoneContainer);


import styled from "@emotion/styled";
import { Button } from "components/ui/Button";
import { ElementContainer } from "components/ui/ElementContainer";
import { ReactComponent as InfoIllustrationSvg } from "assets/images/mobile-doctor-illustration.svg";
import { PageContainer } from "assets/styles/global.styled";
import { ButtonGroup } from "components/ui/ButtonGroup";
import { Select } from "components/ui/Select";
import { ReactComponent as PersonSearchSvg } from "assets/images/person-search.svg";
import { ReactComponent as NotificationSvg } from "assets/images/appointment-notification.svg";

type NotificationProps = {
    $isError?: boolean;
};

export const transientOptions = {
    shouldForwardProp: (propName: string) => !propName.startsWith("$"),
};

export const ContainerDoctorList = styled(PageContainer)``;

export const NextPageButton = styled(Button)`
    width: 100%;
`;

export const InformationContainer = styled(ElementContainer)`
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    align-items: center;
    justify-content: center;

    & > * {
        flex: 1;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletL}) {
        flex-direction: column;
    }
`;

export const InfoContent = styled.div``;

export const InfoParagraph = styled.div`
    margin-bottom: 30px;
`;

export const InfoImage = styled(InfoIllustrationSvg)`
    max-width: 100%;
`;

export const HorizontalAppointmentTypeButtonGroup = styled(ButtonGroup)`
    max-width: 600px;
    font-size: 18px;
    margin-bottom: 20px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        display: none;
        font-size: 16px;
    }

    & > button {
        height: 56px;
    }
`;

export const VerticalAppointmentTypeButtonGroup = styled(ButtonGroup)`
    display: none;
    font-size: 18px;
    margin-bottom: 20px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        display: flex;
        font-size: 16px;
    }

    & > button {
        height: 56px;
    }
`;

export const PersonSearchIcon = styled(PersonSearchSvg)`
    width: 80px;
    height: 80px;
    fill: #9d9faf;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 48px;
        height: 48px;
    }
`;

export const NoDoctorContainer = styled(ElementContainer)`
    color: #838383;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    text-align: center;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        gap: 10px;
    }
`;

export const PaymentMessageContainer = styled(ElementContainer, transientOptions)<NotificationProps>`
    margin-top: 20px;
    background-color: ${({ $isError }) => ($isError ? "#EF5350" : "#66BB6A")};
    color: white;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

export const NotificationIcon = styled(NotificationSvg)`
    width: 34px;
    height: 34px;
    min-width: 34px;
    margin-right: 20px;
    fill: #ffffff;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 22px;
        height: 22px;
        min-width: 22px;
        margin-right: 10px;
    }
`;

export const NotificationContent = styled.span`
    width: fit-content;
    padding-top: 4px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        padding-top: 0;
    }
`;


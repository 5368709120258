import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

type ButtonProps = {
    $selected: boolean;
    $isLoading: boolean;
};

export const transientOptions = {
    shouldForwardProp: (propName: string) => !propName.startsWith("$"),
};

const commonButtonStyled = ({ theme }: Theme) => css`
    display: flex;
    align-items: center;
    height: 65px;
    padding: 0 45px;
    box-sizing: border-box;
    border-radius: 35px;
    font-style: normal;
    font-weight: 800;
    transition: background-color ${theme.global.transition};
    border: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    justify-content: center;

    @media (max-width: ${theme.breakpoints.tablet}) {
        height: 60px;
    }
`;

export const PrimaryButtonStyled = styled("button", transientOptions)<ButtonProps>`
    ${commonButtonStyled}

    color: ${({ theme }) => theme.global.button.primary.font.color};
    font-size: ${({ theme }) => theme.global.button.primary.font.size.desktop};
    background-color: ${({ theme }) => theme.global.button.primary.background.color};
    border: 2px solid ${({ theme }) => theme.global.button.primary.background.color};
    position: relative;
    overflow: hidden;

    &:hover {
        background-color: ${({ theme }) => theme.global.button.primary.background.hoverColor};
        border: 2px solid ${({ theme }) => theme.global.button.primary.background.hoverColor};
    }

    &:disabled {
        background-color: ${({ theme }) => theme.global.button.primary.background.disabledColor};
        border: 2px solid ${({ theme }) => theme.global.button.primary.background.disabledColor};
    }

    &:focus-visible {
        background-color: ${({ theme }) => theme.global.button.primary.background.hoverColor};
        border: 2px solid ${({ theme }) => theme.global.button.primary.background.hoverColor};
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: ${({ theme }) => theme.global.button.primary.font.size.mobile};
    }

    ${({ theme, $isLoading }) =>
        $isLoading &&
        `
    @keyframes button-loading-skeleton {
      100% {
        transform: translateX(100%);
      }
    }

    &:before {
      content: ' ';
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      height: calc(100% + 2px);
      background-repeat: no-repeat;
      background-image: linear-gradient( 90deg, transparent 10%, rgba(255,255,255,0.2), transparent 90%);
      transform: translateX(-100%);
      animation-name: button-loading-skeleton;
      animation-direction: normal;
      animation-duration: 1.5s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  `}
`;

export const SecondaryButtonStyled = styled("button", transientOptions)<ButtonProps>`
    ${commonButtonStyled}

    color: ${({ theme }) => theme.global.button.secondary.font.color};
    background-color: ${({ theme }) => theme.global.button.secondary.background.color};
    font-size: ${({ theme }) => theme.global.button.secondary.font.size.desktop};
    border: 2px solid ${({ theme }) => theme.global.button.secondary.border.color};

    &:hover {
        outline: none;
        color: ${({ theme }) => theme.global.button.secondary.font.hoverColor};
        background-color: ${({ theme }) => theme.global.button.secondary.background.hoverColor};
        border-color: ${({ theme }) => theme.global.button.secondary.border.hoverColor};
    }

    &:disabled {
        color: ${({ theme }) => theme.global.button.secondary.font.disabledColor};
        background-color: ${({ theme }) => theme.global.button.secondary.background.disabledColor};
        border-color: ${({ theme }) => theme.global.button.secondary.border.disabledColor};
    }

    &:focus-visible {
        outline: none;
        color: ${({ theme }) => theme.global.button.secondary.font.hoverColor};
        background-color: ${({ theme }) => theme.global.button.secondary.background.hoverColor};
        border-color: ${({ theme }) => theme.global.button.secondary.border.hoverColor};
    }

    ${({ theme, $selected }) =>
        $selected &&
        `
    outline: none;
    color: ${theme.global.button.secondary.font.hoverColor};
    background-color: ${theme.global.button.secondary.background.hoverColor};
    border-color: ${theme.global.button.secondary.border.hoverColor};
  `}

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: ${({ theme }) => theme.global.button.secondary.font.size.mobile};
    }
`;


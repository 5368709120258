import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";
// @ts-ignore
import { IMaskInput } from "react-imask";

interface StyledInputProps {
    $loading?: boolean;
    $error?: string;
    $centerText: boolean;
    $withIcon?: boolean;
}

interface ErrorMessageProps {
    $centerText: boolean;
}

interface FloatingLabelProps {
    centerText: boolean;
    active: boolean;
    focused: boolean;
}

export const transientOptions = {
    shouldForwardProp: (propName: string) => !propName.startsWith("$"),
};

export const InputWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
        &:disabled {
            color: ${({ theme }) => theme.global.input.font.disabledColor};
            border-color: ${({ theme }) => theme.global.input.border.disabledColor};
            pointer-events: none;
        }
    }
`;

export const InputWithIconWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const styleForInput = ({ theme, $centerText, $error, $withIcon }: Theme & StyledInputProps) => css`
    width: 100%;
    height: 65px;
    border-radius: 5px;
    text-align: ${$centerText ? "center" : "left"};
    padding: 0 ${$withIcon ? 50 : 20}px 0 20px;
    border-color: ${$error ? theme.global.input.border.errorColor : theme.global.input.border.color};
    border-style: solid;
    border-width: ${$error ? 2 : 2}px;
    box-sizing: border-box;
    background-color: ${theme.global.input.background.color};
    transition: ${theme.global.transition}, letter-spacing 0s, font-size 0s;
    font-size: ${theme.global.input.font.size.desktop};
    color: ${theme.global.input.font.color};
    outline: none;

    &::placeholder {
        color: ${theme.global.input.font.placeholerColor};
    }

    &:hover {
        border-color: ${$error ? theme.global.input.border.errorColor : theme.global.input.border.hoverColor};
    }

    &:focus {
        border-color: ${$error ? theme.global.input.border.errorColor : theme.global.input.border.focusColor};
    }

    @media (max-width: ${theme.breakpoints.tablet}) {
        height: 60px;
        font-size: ${theme.global.input.font.size.mobile};
    }
`;

export const StyledInput = styled.input<StyledInputProps>`
    ${styleForInput};
`;

export const StyledInputMask = styled(IMaskInput, transientOptions)`
    ${styleForInput};
`;

export const ErrorMessage = styled.span<ErrorMessageProps>`
    margin-top: 10px;
    text-align: ${({ $centerText }) => ($centerText ? "center" : "")};
    width: 100%;
    color: ${({ theme }) => theme.global.input.errorMessage.color};
`;

export const FloatingLabel = styled.span<FloatingLabelProps>`
    padding: 0 4px;
    position: absolute;
    z-index: 2;
    top: ${({ active }) => (active ? `0` : `50%`)};
    left: ${({ centerText }) => (centerText ? "50%" : "20px")};
    transform: translate(${({ centerText }) => (centerText ? "-50%" : "0")}, -50%);
    ${({ active, theme }) => (active ? `font-size: ${theme.global.input.label.active.font.size.desktop};` : null)}
    transition: all ${({ theme }) => theme.global.transition};
    color: ${({ theme }) => theme.global.input.font.placeholerColor};
    background: linear-gradient(0, ${(props) => props.theme.global.input.background.color} 50%, transparent 50%);
    user-select: none;
    pointer-events: none;
    white-space: nowrap;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: ${({ theme }) => theme.global.input.label.active.font.size.mobile};
    }
`;

export const IconWrapper = styled.button`
    position: absolute;
    z-index: 3;
    top: 50%;
    right: 15px;
    height: 80%;
    min-width: 30px;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    padding: 0;
    width: fit-content;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    svg {
        fill: #9d9faf;
    }

    &:hover {
        svg {
            fill: #3500cb;
        }
    }
`;


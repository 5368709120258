import ReactDom from "react-dom";
import React, { memo, useEffect, useRef } from "react";
import { ReactComponent as CloseSvg } from "assets/images/close.svg";
import { ModalWrapper, ContentWrapper, CloseButton } from "./Modal.styled";
import { useOutsideClick } from "hooks/useOutsideClick";

type ModalContainerType = {
    className?: string;
    children: React.ReactNode;
    onClose: () => void;
};

const ModalContainer: React.FC<ModalContainerType> = (props) => {
    const { className, children, onClose } = props;
    const ref = useRef(null);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);

    useOutsideClick(ref, () => onClose(), true);

    return ReactDom.createPortal(
        <ModalWrapper className={className}>
            <ContentWrapper ref={ref}>
                <CloseButton onClick={() => onClose()}>
                    <CloseSvg />
                </CloseButton>
                {children}
            </ContentWrapper>
        </ModalWrapper>,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        document.getElementById("modal-root")!
    );
};

export const Modal: React.FC<ModalContainerType> = memo(ModalContainer);


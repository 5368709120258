import React, { useState } from "react";
import { patternPhoneForm } from "utils/validation";
import { Input } from "components/ui/Input";
import { MASK_PHONE } from "constants/pageAuth";
import { Controller } from "react-hook-form";
import { ControllerType } from "entities/Forms";

type ControllerPhoneProps = ControllerType & { disabled?: boolean };

export const ControllerPhone: React.FC<ControllerPhoneProps> = (props) => {
    const { name, control, loading, error, disabled } = props;
    const [firstEntry, setFirstEntry] = useState(true);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            rules={{
                pattern: patternPhoneForm,
                required: "Пожалуйста введите ваш номер телефона.",
            }}
            render={({ field: { onChange, value }, fieldState }) => {
                const errorMessage = fieldState?.error?.message;

                return (
                    <Input
                        placeholder="Телефон +7..."
                        centerText
                        onChangeText={(value) => {
                            let newValue = value;
                            const lengthPhone = value.length;

                            if (firstEntry && value === "+7 (8") {
                                newValue = value.substring(0, lengthPhone - 1);
                                setFirstEntry(false);
                            }

                            if (value.length === 0) {
                                setFirstEntry(true);
                            }

                            onChange(newValue);
                        }}
                        type="tel"
                        value={value}
                        loading={loading}
                        error={errorMessage || error}
                        mask={MASK_PHONE}
                        autoFocus
                        disabled={disabled}
                    />
                );
            }}
        />
    );
};

import React, { memo } from "react";

import { CheckboxLabel, CheckboxIconWrapper, HiddenCheckbox, CheckboxTextWrapper } from "./Checkbox.styled";

type CheckboxPropsType = {
    label: string;
    value: boolean;
    disabled?: boolean;
    className?: string;
    onChange: (checked: boolean) => void;
    children: React.ReactNode;
    error?: string;
};

const CheckboxContainer = (props: CheckboxPropsType): React.ReactElement => {
    const { label, value, disabled = false, className, onChange, children, error } = props;

    const clickHandler = () => {
        onChange(!value);
    };

    return (
        <CheckboxLabel className={className} checked={value}>
            <HiddenCheckbox onChange={clickHandler} checked={value} type="checkbox" disabled={disabled} />
            <CheckboxIconWrapper checked={value} error={!!error}></CheckboxIconWrapper>
            <CheckboxTextWrapper>{children}</CheckboxTextWrapper>
        </CheckboxLabel>
    );
};

export const Checkbox = memo(CheckboxContainer);


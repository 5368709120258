class YaMetrika {
    private readonly counterId: number;

    constructor(counterId: number) {
        this.counterId = counterId;
    }

    public initialize(mainMetrika: boolean = false) {
        if (!(window as any).ym) {
            console.error("Yandex Metrika script is not loaded.");
            return;
        }

        const initParams = mainMetrika
            ? {
                  clickmap: true,
                  trackLinks: true,
                  accurateTrackBounce: true,
                  webvisor: true,
              }
            : {
                  clickmap: false,
                  trackLinks: true,
                  accurateTrackBounce: true,
                  webvisor: false,
              };
        (window as any).ym(this.counterId, "init", initParams);
    }

    public reachGoal(goalName: string, callback?: () => void) {
        if (!(window as any).ym) {
            console.error("Yandex Metrika script is not loaded.");
            return;
        }

        (window as any).ym(this.counterId, "reachGoal", goalName, undefined, () => {
            if (callback && typeof callback === "function") {
                console.error("Yandex Metrika reachGoal success", this.counterId);
                callback();
            }
        });
    }
}

export default YaMetrika;


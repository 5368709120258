import React, { memo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "stores/store";
import { BranchSelectWrapper, BranchSelectItemWrapper, ContentWrapper, BranchTitle, BranchAddress } from "./BranchSelect.styled";
import { BlockWrapper, HorizontalRule } from "assets/styles/global.styled";
import { ElementContainer } from "components/ui/ElementContainer";

type BranchSelectType = {
    activeBranchId: number;
    onClick: (id: number) => void;
};

const BranchSelectContainer: React.FC<BranchSelectType> = (props) => {
    const { activeBranchId, onClick } = props;

    const { branches } = useSelector((state: RootState) => state.doctors);

    if (!branches.data || !branches.data.length) {
        return <></>;
    }

    return (
        <BranchSelectWrapper>
            <div>Выберите филиал клиники</div>
            <HorizontalRule />
            <ContentWrapper>
                {branches.data.map((x, i) => (
                    <BranchSelectItemWrapper 
                        key={i}
                        $active={activeBranchId === x.id}
                        onClick={() => onClick(x.id)}>
                            <BranchTitle>{x.name}</BranchTitle>
                            <BranchAddress>{x.address}</BranchAddress>
                    </BranchSelectItemWrapper>
                ))}
            </ContentWrapper>
        </BranchSelectWrapper>
    );
};

export const BranchSelect: React.FC<BranchSelectType> = memo(BranchSelectContainer);


import { SENDING_CODE_INTERVAL_SEC } from "constants/pageAuth";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendPhoneSaga } from "stores/authorizationSlice";
import { RootState } from "stores/store";
import { BtnRepeatContainerAuth, BtnRepeatSmsCodeAuth, HelperTextRepeatSmsCodeAuth } from "./BtnRepeatSmsCode.styled";

const BtnRepeatSmsCodeContainer: React.FC = () => {
    const dispatch = useDispatch();
    const { phone, lastSendingCodeTimestamp } = useSelector((state: RootState) => state.authorization);

    const mounted = useRef(false);
    const [time, setTime] = useState(0);
    const [isTimer, setIsTimer] = useState(false);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (time === 0) {
            setIsTimer(false);
        } else {
            setTimeout(() => {
                setTime(time - 1);
            }, 1000);
        }
    }, [time]);

    useEffect(() => {
        if (!lastSendingCodeTimestamp) return;

        const diffMillis = Date.now() - lastSendingCodeTimestamp;
        const diffSec = Math.floor(diffMillis / 1000);
        if (diffSec < SENDING_CODE_INTERVAL_SEC) {
            setTime(SENDING_CODE_INTERVAL_SEC - diffSec);
            setIsTimer(true);
        } else {
            setTime(0);
        }
    }, [lastSendingCodeTimestamp]);

    const renderTime = (currentTime: number) => {
        const seconds = currentTime % 60;
        const minute = Math.floor(currentTime / 60);

        return (
            <>
                {minute < 10 ? `0${minute}` : minute}:{seconds < 10 && "0"}
                {seconds}
            </>
        );
    };

    const handleClickSendCode = () => {
        dispatch(sendPhoneSaga({ phone: phone }));
    };

    return (
        <>
            {mounted.current && (
                <BtnRepeatContainerAuth>
                    {isTimer ? (
                        <HelperTextRepeatSmsCodeAuth>Запрос нового звонка доступен через: {renderTime(time)}</HelperTextRepeatSmsCodeAuth>
                    ) : (
                        <BtnRepeatSmsCodeAuth title="Повторить звонок" url="" onClick={handleClickSendCode} />
                    )}
                </BtnRepeatContainerAuth>
            )}
        </>
    );
};

export const BtnRepeatSmsCode = memo(BtnRepeatSmsCodeContainer);


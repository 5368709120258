const colors = {
    black: "#0E0E0E",
    black02: "#3A3B41",
    grey: "#C0C0C2",
    grey02: "#838383",
    grey03: "#C4CAE0",
    blue: "#2C41FF",
    blue02: "#3500CB",
    red: "#FF4848",
    white: "#FFFFFF",
};

const fontSizes = {
    xSmall: "14px",
    small: "16px",
    medium: "18px",
    regular: "20px",
    large: "22px",
    xLarge: "24px",
};

export const themeStyles = {
    // Брейкпоинты

    breakpoints: {
        mobile: "320px",
        mobileL: "576px",
        tablet: "767px",
        tabletL: "1024px",
        desktop: "1440px",
        desktopL: "1920px",
    },

    // Global - Глобальные стили

    global: {
        header: {
            color: colors.black,
            h1: {
                font: {
                    size: { desktop: "44px", mobile: "32px" },
                },
            },
            h2: {
                font: {
                    size: { desktop: "28px", mobile: "20px" },
                },
            },
        },
        text: {
            color: colors.black02,
            font: {
                sizes: fontSizes,
                size: { desktop: fontSizes.regular, mobile: fontSizes.small },
            },
        },
        input: {
            border: {
                color: colors.grey03,
                focusColor: colors.blue,
                hoverColor: colors.blue02,
                errorColor: colors.red,
                disabledColor: colors.grey03,
            },
            background: {
                color: colors.white,
            },
            font: {
                color: colors.black02,
                disabledColor: colors.grey02,
                loadingColor: colors.grey02,
                placeholerColor: colors.grey02,
                size: { desktop: fontSizes.regular, mobile: fontSizes.small },
            },
            errorMessage: {
                color: colors.red,
            },
            label: {
                active: {
                    font: {
                        size: { desktop: fontSizes.medium, mobile: fontSizes.small },
                    },
                },
            },
        },
        checkbox: {
            font: {
                size: { desktop: fontSizes.small, mobile: fontSizes.xSmall },
            },
            border: {
                color: colors.blue,
                focusColor: colors.blue02,
                disabledColor: colors.grey03,
            },
            background: {
                hoverColor: colors.grey03,
                focusColor: colors.grey03,
            },
            checked: {
                background: {
                    color: colors.blue,
                    focusColor: colors.blue02,
                    disabledColor: colors.grey03,
                },
            },
        },
        button: {
            primary: {
                background: {
                    color: colors.blue,
                    hoverColor: colors.blue02,
                    disabledColor: colors.grey03,
                },
                font: {
                    color: colors.white,
                    size: { desktop: fontSizes.regular, mobile: fontSizes.small },
                },
            },
            secondary: {
                background: {
                    color: colors.white,
                    hoverColor: colors.blue02,
                    disabledColor: colors.white,
                },
                font: {
                    color: colors.black,
                    hoverColor: colors.white,
                    disabledColor: colors.grey03,
                    size: { desktop: fontSizes.regular, mobile: fontSizes.small },
                },
                border: {
                    color: colors.grey03,
                    hoverColor: colors.blue02,
                    disabledColor: colors.grey03,
                },
            },
            text: {
                font: {
                    color: colors.grey02,
                },
            },
        },
        link: {
            font: {
                color: colors.blue,
                hoverColor: colors.blue02,
                activeColor: colors.blue02,
                focusColor: colors.blue02,
            },
        },
        transition: "0.3s ease",
    },

    // Pages - переопределение для глобальных стилей в определенных местах

    pages: {
        authorizationPage: {
            changeNumberBtn: {
                font: {
                    color: colors.grey02,
                },
            },
            helperText: {
                font: {
                    color: colors.grey02,
                    size: { desktop: fontSizes.small, mobile: fontSizes.xSmall },
                },
            },
        },
    },
};


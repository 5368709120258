import { STORAGE_URL } from "constants/customInfo";
import { SingleAppointmentState } from "stores/appointmentsSlice";

export class AppointmentConverter {
    convertAppointmentToState = (data: any): SingleAppointmentState => {
        return {
            id: data.id,
            type: data.type,
            startDatetime: data.start_datetime * 1000,
            doctor: {
                name: `${data.doctor.last_name} ${data.doctor.first_name} ${data.doctor.middle_name}`,
                specialization: data.doctor.specialization,
                image: new URL(data.doctor.photo, STORAGE_URL).href,
                meetingLink: data.doctor.meeting_link,
                address: data.doctor.address,
            },
            patient: {
                address: data.patient.address,
            },
            invoice: {
                sum: data.invoice.out_sum,
                status: data.invoice.status,
                invoiceId: data.invoice.invoice_id,
                paymentUrl: data.invoice.payment_url,
                cancelable: data.invoice.cancelable
            },
        };
    };
}


import React, { useState } from "react";
import { commonRules, patternName, patternRussianName } from "utils/validation";
import { Input } from "components/ui/Input";
import { Controller } from "react-hook-form";
import { ControllerType } from "entities/Forms";

type ControllerNameProps = ControllerType & {
    label: string;
    autoFocus?: boolean;
    disabled?: boolean;
};

export const ControllerName: React.FC<ControllerNameProps> = (props) => {
    const { name, control, error, label, autoFocus = false, disabled, loading } = props;

    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            rules={{
                ...commonRules,
                pattern: patternRussianName,
            }}
            render={({ field: { onChange, onBlur, value }, fieldState }) => {
                const errorMessage = fieldState?.error?.message;

                return (
                    <Input
                        label={label}
                        centerText
                        onChangeText={(value) => {
                            const newValue = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

                            if (patternName.value.test(newValue)) onChange(newValue);
                        }}
                        onBlur={onBlur}
                        value={value}
                        error={errorMessage || error}
                        autoFocus={autoFocus}
                        loading={loading}
                        disabled={disabled}
                    />
                );
            }}
        />
    );
};


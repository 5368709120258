import styled from "@emotion/styled";
import { PageContainer, ResponseContainer } from "assets/styles/global.styled";
import { Link } from "components/ui/Link";

export const FooterContainer = styled(PageContainer)``;

export const FooterResponseContainer = styled(ResponseContainer)`
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0 40px;
    border-top: 1px solid #c4cae0;

    @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
        padding: 10px 0 30px;
        gap: 15px;
        flex-direction: column;
    }
`;

export const NavbarLink = styled(Link)`
    color: #3a3b41;

    svg {
        fill: #9d9faf;
    }

    &:hover {
        color: #2c41ff;

        svg {
            fill: #2c41ff;
        }
    }

    &:focus-visible {
        color: #2c41ff;

        svg {
            fill: #2c41ff;
        }
    }

    &:active,
    &.active {
        color: #2c41ff;

        svg {
            fill: #2c41ff;
        }
    }
`;

export const PhoneLink = styled(NavbarLink)`
    flex-grow: 1;
`;


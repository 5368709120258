import React, { memo } from "react";
import { PrimaryButtonStyled, SecondaryButtonStyled } from "./Button.styled";
import { FetchStatus } from "entities/Enums";

type ButtonType = {
    className?: string;
    children: any;
    type?: any;
    typeStyled?: "primary" | "secondary";
    disabled?: boolean;
    loading?: FetchStatus;
    selected?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const ButtonContainer: React.FC<ButtonType> = (props) => {
    const { className, children, type, typeStyled = "primary", onClick, disabled, loading, selected = false } = props;
    const isLoading = loading === FetchStatus.Fetching;

    const handleClickButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (isLoading) {
            e.preventDefault();
            return false;
        }

        if (onClick) {
            onClick(e);
        }
    };

    return (
        <>
            {typeStyled === "primary" && (
                <PrimaryButtonStyled
                    className={className}
                    type={type}
                    disabled={disabled}
                    $isLoading={isLoading}
                    $selected={selected}
                    onClick={handleClickButton}
                >
                    {children}
                </PrimaryButtonStyled>
            )}
            {typeStyled === "secondary" && (
                <SecondaryButtonStyled
                    className={className}
                    type={type}
                    disabled={disabled || isLoading}
                    $isLoading={isLoading}
                    $selected={selected}
                    onClick={onClick}
                >
                    {children}
                </SecondaryButtonStyled>
            )}
        </>
    );
};

export const Button: React.FC<ButtonType> = memo(ButtonContainer);


import styled from "@emotion/styled";
import { ReactComponent as UpDownSvg } from "assets/images/up-down.svg";
import { Input } from "../Input";

type SelectProps = {
    $opened: boolean;
};

export const transientOptions = {
    shouldForwardProp: (propName: string) => !propName.startsWith("$"),
};

export const SelectWrapper = styled.div``;

export const StyledInput = styled(Input)`
    input {
        cursor: pointer;
    }
`;

export const DropdownWrapper = styled("div", transientOptions)<SelectProps>`
    position: relative;
    width: 100%;
    z-index: 10;

    ${({ $opened }) => ($opened ? "" : "display: none;")}
`;

export const Dropdown = styled.div`
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #c4cae0;
    background: white;
    max-height: 330px;

    li {
    }
`;

export const DropdownListWrapper = styled.div`
    max-height: 310px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-track {
        background: #c4cae0;
        border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
        background: #2c41ff;
        border-radius: 2px;
    }
`;

export const DropdownList = styled.ul`
    padding: 0;
    margin: 0;
    list-style-type: none;
`;

export const DropdownItem = styled.li`
    cursor: pointer;
    padding: 10px 15px;
    margin-right: 10px;
    transition: ${(props) => props.theme.transition};
    display: flex;
    align-items: center;
    border-radius: 3px;

    &:hover {
        background-color: #eaeaea;
    }
`;

export const EmptyDropdownItem = styled(DropdownItem)`
    color: #838383;
`;

export const UpDownIcon = styled(UpDownSvg, transientOptions)<SelectProps>`
    width: 15px;
    ${({ $opened }) => ($opened ? "" : "transform: rotate(180deg);")}
`;


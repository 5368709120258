import { useEffect, RefObject } from "react";

export const useOutsideClick = (
    ref: RefObject<HTMLElement>,
    callback: (e?: MouseEvent) => void,
    focused: boolean,
    otherDeps: any[] = []
): void => {
    const handleMouseDown = (e: MouseEvent) => {
        if (ref.current?.contains(e.target as Node)) {
            return;
        }

        callback(e);
    };

    useEffect(() => {
        if (focused) {
            document.addEventListener("mousedown", handleMouseDown);

            return () => {
                document.removeEventListener("mousedown", handleMouseDown);
            };
        }
    }, [focused, ...otherDeps]);
};


import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "components/ui/Button";
import { ElementContainer } from "components/ui/ElementContainer";

export const DoctorItemContainer = styled(ElementContainer)`
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 30px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletL}) {
        gap: 20px;
        margin-bottom: 20px;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

export const HorizontalRule = styled.hr`
    display: none;
    margin-top: 0;
    margin-bottom: 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletL}) {
        display: block;
    }
`;

export const DoctorInfoWrapper = styled.div`
    display: flex;
    gap: 25px;
    align-items: center;
    min-width: 400px;
    align-items: flex-start;
    flex: 1;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        min-width: auto;
    }
`;

export const DoctorImage = styled.img`
    width: 155px;
    height: 155px;
    min-width: 155px;
    border-radius: 50%;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        width: 75px;
        height: 75px;
        min-width: 75px;
    }
`;

export const DoctorDefaultImage = styled.div`
    width: 155px;
    height: 155px;
    min-width: 155px;
    background: #f6f6f6;
    border-radius: 50%;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        width: 75px;
        height: 75px;
        min-width: 75px;
    }
`;

export const DoctorSpecialization = styled.div`
    margin-bottom: 10px;
    color: #2c41ff;
`;

export const DoctorName = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
    word-break: break-word;
`;

export const DoctorExperience = styled.div`
    margin-bottom: 10px;
`;

export const DoctorPrice = styled.div`
    font-weight: bold;
`;

export const ScheduleWrapper = styled.div`
    flex: 1;
`;

export const ScheduleTitle = styled.div`
    font-weight: bold;
    margin-bottom: 15px;
`;

export const ScheduleCalendar = styled.div`
    margin-bottom: 20px;
`;

export const CalendarRow = styled.div`
    display: flex;
    gap: 15px;
    margin-bottom: 15px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        gap: 10px;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

export const CalendarHeaderRow = styled(CalendarRow)`
    margin-bottom: 7px;
`;

const calendarCellStyle = ({ theme }: Theme) => `
  width: 38px;
  min-width: 38px;
  font-size: 18px;
  font-weight: normal;
  padding: 0;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: ${theme.breakpoints.tablet}) {
    width: 32px;
    min-width: 32px;
    font-size: 16px;
  }
`;

export const CalendarCell = styled.div`
    ${calendarCellStyle}

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CalendarDay = styled(Button)`
    ${calendarCellStyle}

    height: 38px;
    border-radius: 5px;
    border-width: 1px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        height: 32px;
    }
`;

export const SelectedDayWrapper = styled.div``;

export const SelectedDate = styled.div`
    margin-bottom: 10px;
`;

export const SelectedDateSlots = styled.div`
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
`;

export const DateSlot = styled(CalendarDay)`
    width: 90px;
    min-width: 90px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 65px;
        min-width: 65px;
    }
`;

// Modal

export const InfoParagraph = styled.div`
    margin-bottom: 15px;
`;

export const ModalDoctorItemContainer = styled.div`
    background-color: #f6f6f6;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        padding: 15px;
        margin-bottom: 20px;
        gap: 10px;
    }
`;

export const ModalInfoWrapper = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
    min-width: 300px;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        gap: 10px;
    }

    svg {
        fill: #9d9faf;
        width: 34px;
        height: 34px;
        min-width: 34px;
        margin: 0 13px;

        @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
            margin: 0 6px;
        }
    }
`;

export const ModalDoctorInfoWrapper = styled(ModalInfoWrapper)`
    align-items: flex-start;
`;

export const ModalDoctorImage = styled.img`
    width: 60px;
    height: 60px;
    min-width: 60px;
    border-radius: 50%;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        width: 46px;
        height: 46px;
        min-width: 46px;
    }
`;

export const ModalDefaultDoctorImage = styled.div`
    width: 60px;
    height: 60px;
    min-width: 60px;
    border-radius: 50%;
    background: #f6f6f6;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        width: 46px;
        height: 46px;
        min-width: 46px;
    }
`;

export const ModalDoctorName = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
    word-break: break-word;
`;

export const ModalDoctorSpecialization = styled.div`
    margin-bottom: 10px;
`;

export const ModalDoctorPrice = styled.div`
    font-weight: bold;
`;

export const FormContainer = styled.form``;

export const InputContainer = styled.div`
    margin-bottom: 30px;
`;

export const ButtonSubmitForm = styled(Button)`
    width: 100%;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-top: 20px;
    }
`;


import styled from "@emotion/styled";

interface CheckboxLabelProps {
    checked: boolean;
    error?: boolean;
}

export const CheckboxLabel = styled.label<CheckboxLabelProps>`
    display: flex;
    width: fit-content;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    transition: ${(props) => props.theme.transition};
`;

export const HiddenCheckbox = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;

    &:focus-visible {
        & + div {
            outline: ${({ theme }) => theme.global.checkbox.border.focusColor} auto 1px;
            border-color: ${({ theme }) => theme.global.checkbox.border.focusColor};
            background-color: ${({ theme }) => theme.global.checkbox.background.focusColor};

            &::after {
                background-color: ${({ theme, checked }) => (checked ? theme.global.checkbox.checked.background.focusColor : null)};
            }
        }
    }

    &:disabled {
        & + div {
            border-color: ${({ theme }) => theme.global.checkbox.border.disabledColor};

            &::after {
                background-color: ${({ theme, checked }) => (checked ? theme.global.checkbox.checked.background.disabledColor : null)};
            }
        }
    }
`;

export const CheckboxIconWrapper = styled.div<CheckboxLabelProps>`
    width: 22px;
    height: 22px;
    min-width: 22px;
    border-radius: 4px;
    margin-right: 10px;
    border: solid 2px ${({ theme, error }) => (error ? "#FF4848" : theme.global.checkbox.border.color)};
    transition: ${(props) => props.theme.transition};

    &:hover {
        background-color: ${({ theme }) => theme.global.checkbox.background.hoverColor};
    }

    &::after {
        display: block;
        content: "";
        position: relative;
        width: 16px;
        height: 16px;
        top: 3px;
        left: 3px;
        border-radius: 2px;
        background-color: ${({ theme, checked, error }) =>
            checked ? (error ? "#FF4848" : theme.global.checkbox.checked.background.color) : null};
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 18px;
        height: 18px;
        min-width: 18px;
        border-radius: 3px;

        &::after {
            width: 12px;
            height: 12px;
            top: 3px;
            left: 3px;
            border-radius: 2px;
        }
    }
`;

export const CheckboxTextWrapper = styled.span`
    margin-top: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: ${({ theme }) => theme.global.checkbox.font.size.desktop};
    user-select: none;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: ${({ theme }) => theme.global.checkbox.font.size.mobile};
    }
`;


import React, { memo } from "react";
import { LayoutWrapper, LayoutMaxContainer } from "./Layout.styled";

const LayoutContainer = ({ children }: { children: React.ReactNode }): React.ReactElement => {
    return (
        <LayoutWrapper>
            <LayoutMaxContainer>{children}</LayoutMaxContainer>
        </LayoutWrapper>
    );
};

export const Layout = memo(LayoutContainer);


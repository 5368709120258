export const patternPhoneForm = {
    value: /^(\+7|7)?[\s-]?\(?[0-9][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/,
    message: "Неверно указан номер телефона. Пожалуйста проверьте правильность ввода.",
};

export const patternName = {
    value: /^[a-zA-Zа-яА-Я]{0,30}$/,
    message: "Поле должно содержать только буквенные символы",
};

export const patternRussianName = {
    value: /^[а-яА-Я]{0,30}$/,
    message: "Используйте русские буквы",
};

export const commonRules = {
    required: "Поле обязательно для заполнения.",
};


import React from "react";
import { MASK_CODE } from "constants/pageAuth";
import { Controller } from "react-hook-form";
import { ControllerType } from "entities/Forms";
import { Input } from "components/ui/Input";

type ControllerOtpProps = ControllerType & {};

export const ControllerOtp: React.FC<ControllerOtpProps> = (props) => {
    const { name, control, loading, error } = props;

    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            rules={{
                required: "Введите последние 4 цифры входящего номера.",
                minLength: {
                    value: 4,
                    message: "Пожалуйста проверьте правильность ввода. Код должен содержать 4 цифры.",
                },
            }}
            render={({ field: { onChange, value }, fieldState }) => {
                const errorMessage = fieldState?.error?.message;

                return (
                    <Input
                        placeholder="XXXX"
                        label="Введите последние 4 цифры"
                        centerText
                        onChangeText={onChange}
                        value={value}
                        loading={loading}
                        error={errorMessage || error}
                        mask={MASK_CODE}
                        type="tel"
                        autoFocus
                        pattern="[0-9]*"
                    />
                );
            }}
        />
    );
};

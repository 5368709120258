import React, { memo } from "react";
import { LinkStyled, LinkStyledDefault, LinkIcon } from "./Link.styled";

type LinkType = {
    title?: string;
    url: string;
    icon?: React.ReactElement;
    type?: "outside" | "link" | "navlink";
    className?: string;
    onClick?: () => void;
    target?: string;
    children?: React.ReactNode;
};

const LinkContainer = (props: LinkType): React.ReactElement => {
    const { title, url, icon, type = "link", className, onClick, target, children } = props;

    return (
        <>
            {type === "outside" && (
                <LinkStyledDefault className={className} href={url} title={title} onClick={onClick} target={target} $hasIcon={!!icon}>
                    {icon && <LinkIcon>{icon}</LinkIcon>}
                    {!children && <span>{title}</span>}
                    {!!children && children}
                </LinkStyledDefault>
            )}

            {type === "link" && (
                <LinkStyled className={className} to={url} title={title} onClick={onClick} target={target} $hasIcon={!!icon}>
                    {icon && <LinkIcon>{icon}</LinkIcon>}
                    {!children && <span>{title}</span>}
                    {!!children && children}
                </LinkStyled>
            )}

            {type === "navlink" && <></>}
        </>
    );
};

export const Link = memo(LinkContainer);


import { ActionWithPayloadType, HTTPMethodType } from "entities/Redux";
import { put, takeLatest } from "redux-saga/effects";
import {
    checkOtpKeySaga,
    registerProfileSaga,
    sendPhoneSaga,
    setAuthorizationLoadingState,
    setFieldErrorMessage,
    setLastSendingCodeTimestamp,
    setOtpKey,
    setPhone,
} from "stores/authorizationSlice";
import { CheckOtpKeyType, SendPhoneType } from "stores/types/authorizationTypes";
import { setToken, setUserProfile } from "stores/userSlice";
import { UserConverter } from "./converters/userConverter";
import { createActionType, createCommonRequest } from "./shared";
import { FetchStatus } from "entities/Enums";
import { UserProfileState } from "stores/types/userTypes";

export const { GET, POST, PUT, DEL } = HTTPMethodType;

const sendPhoneSagaActions = createActionType(sendPhoneSaga.toString());
const checkOtpKeySagaActions = createActionType(checkOtpKeySaga.toString());
const registerProfileSagaActions = createActionType(registerProfileSaga.toString());

function* sendPhoneAuthWorker({ payload }: ActionWithPayloadType<SendPhoneType>) {
    yield put(setPhone(payload.phone));

    const sendPhoneAuthRequest = createCommonRequest(sendPhoneSaga.toString(), POST, "auth/patient/phone/");
    yield sendPhoneAuthRequest({
        ...payload,
        phone: payload.phone.replace(/\D/g, ""),
    });
}

function* sendPhoneStartAuthWorker() {
    yield put(setAuthorizationLoadingState({ loading: FetchStatus.Fetching }));
}

function* sendPhoneSuccessAuthWorker({ payload }: ActionWithPayloadType<any>) {
    yield put(setLastSendingCodeTimestamp(Date.now()));
    yield put(setOtpKey(payload.data.key));
    yield put(setAuthorizationLoadingState({ loading: FetchStatus.Complete }));
}

function* sendPhoneFailAuthWorker() {
    yield put(setAuthorizationLoadingState({ loading: FetchStatus.Fail }));
}

function* sendPhoneInvalidAuthWorker({ payload }: ActionWithPayloadType<any>) {
    const phoneErrors = (payload?.phone && Array.isArray(payload.phone) ? payload.phone : []) as string[];
    const firstPhoneError = phoneErrors.length > 0 ? phoneErrors[0] : "";

    if (firstPhoneError.toLowerCase().includes("лимит")) {
        yield put(setFieldErrorMessage({ phone: "Превышен лимит, повторите попытку позже" }));
    } else {
        yield put(setFieldErrorMessage({ phone: "Пожалуйста, проверьте номер телефона" }));
    }
}

function* sendPhoneServerAuthWorker() {
    yield put(
        setAuthorizationLoadingState({
            loading: FetchStatus.Fail,
            error: "Произошла техническая ошибка, мы уже работаем над ее устранением",
        })
    );
}

function* checkOtpKeyAuthWorker({ payload }: ActionWithPayloadType<CheckOtpKeyType>) {
    const checkOtpKeyAuthRequest = createCommonRequest(checkOtpKeySaga.toString(), POST, "auth/patient/phone/otp/");
    yield checkOtpKeyAuthRequest(payload);
}

function* checkOtpKeyStartAuthWorker() {
    yield put(setAuthorizationLoadingState({ loading: FetchStatus.Fetching }));
}

function* checkOtpKeySuccessAuthWorker({ payload }: ActionWithPayloadType<any>) {
    const userState = new UserConverter().convertUserProfileToState(payload.data.user);
    yield put(setUserProfile(userState));
    yield put(setToken(payload.data.code));
    yield put(setAuthorizationLoadingState({ loading: FetchStatus.Complete }));
}

function* checkOtpKeyFailAuthWorker() {
    yield put(setAuthorizationLoadingState({ loading: FetchStatus.Fail }));
}

function* checkOtpKeyInvalidAuthWorker({ payload }: ActionWithPayloadType<any>) {
    const otpErrors = (payload?.otp && Array.isArray(payload.otp) ? payload.otp : []) as string[];
    const firstOtpError = otpErrors.length > 0 ? otpErrors[0] : "";

    if (firstOtpError === "Достигнуто максимальное количество попыток") {
        yield put(setFieldErrorMessage({ otp: "Превышен лимит попыток, повторите звонок" }));
    } else {
        yield put(setFieldErrorMessage({ otp: "Проверьте введенный код" }));
    }
}

function* checkOtpKeyServerAuthWorker() {
    yield put(
        setAuthorizationLoadingState({
            loading: FetchStatus.Fail,
            error: "Произошла техническая ошибка, мы уже работаем над ее устранением",
        })
    );
}

function* registerProfileWorker({ payload }: ActionWithPayloadType<UserProfileState>) {
    const registerProfileRequest = createCommonRequest(registerProfileSaga.toString(), POST, "auth/patient/register/");
    const { data } = yield registerProfileRequest({
        tos_are_agreed: payload.tosAreAgreed,
        first_name: payload.firstName,
        last_name: payload.lastName,
    });

    if (data && data.registered) {
        yield put(
            setUserProfile({
                ...payload,
                registered: !!data.registered,
            })
        );
    }
}

function* registerProfileStartWorker() {
    yield put(setAuthorizationLoadingState({ loading: FetchStatus.Fetching }));
}

function* registerProfileSuccessWorker() {
    yield put(setAuthorizationLoadingState({ loading: FetchStatus.Complete }));
}

function* registerProfileFailWorker() {
    yield put(setAuthorizationLoadingState({ loading: FetchStatus.Fail }));
}

function* registerProfileInvalidWorker({ payload }: ActionWithPayloadType<any>) {
    const convertProfileErrors = new UserConverter().convertUserProfileErrors(payload);

    if (convertProfileErrors.profile) {
        yield put(setAuthorizationLoadingState({ loading: FetchStatus.Fail, error: convertProfileErrors?.profile }));
    }

    yield put(setFieldErrorMessage(convertProfileErrors));
}

function* registerProfileServerWorker() {
    yield put(
        setAuthorizationLoadingState({
            loading: FetchStatus.Fail,
            error: "Произошла техническая ошибка, мы уже работаем над ее устранением",
        })
    );
}

export function* authorizationWatcher() {
    yield takeLatest(sendPhoneSagaActions.name, sendPhoneAuthWorker);
    yield takeLatest(sendPhoneSagaActions.start, sendPhoneStartAuthWorker);
    yield takeLatest(sendPhoneSagaActions.success, sendPhoneSuccessAuthWorker);
    yield takeLatest(sendPhoneSagaActions.fail, sendPhoneFailAuthWorker);
    yield takeLatest(sendPhoneSagaActions.invalid, sendPhoneInvalidAuthWorker);
    yield takeLatest(sendPhoneSagaActions.server, sendPhoneServerAuthWorker);

    yield takeLatest(checkOtpKeySagaActions.name, checkOtpKeyAuthWorker);
    yield takeLatest(checkOtpKeySagaActions.start, checkOtpKeyStartAuthWorker);
    yield takeLatest(checkOtpKeySagaActions.success, checkOtpKeySuccessAuthWorker);
    yield takeLatest(checkOtpKeySagaActions.fail, checkOtpKeyFailAuthWorker);
    yield takeLatest(checkOtpKeySagaActions.invalid, checkOtpKeyInvalidAuthWorker);
    yield takeLatest(checkOtpKeySagaActions.server, checkOtpKeyServerAuthWorker);

    yield takeLatest(registerProfileSagaActions.name, registerProfileWorker);
    yield takeLatest(registerProfileSagaActions.start, registerProfileStartWorker);
    yield takeLatest(registerProfileSagaActions.success, registerProfileSuccessWorker);
    yield takeLatest(registerProfileSagaActions.fail, registerProfileFailWorker);
    yield takeLatest(registerProfileSagaActions.invalid, registerProfileInvalidWorker);
    yield takeLatest(registerProfileSagaActions.server, registerProfileServerWorker);
}


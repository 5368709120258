import { ConfigState } from "stores/types/applicationTypes";

export class ConfigConverter {
    convertConfig = (data: any): ConfigState => {
        const {
            enabled_appointments: { online, inside, outside },
            doctors_filtration_type,
            doctors_page_size,
        } = data;

        const convertData: ConfigState = {
            enabledAppointments: {
                online,
                inside,
                outside,
            },
            doctorsFiltrationType: doctors_filtration_type,
            doctorsPageSize: doctors_page_size,
        };

        return convertData;
    };
}


import styled from "@emotion/styled";

export const Container = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.02);
    padding: 40px;

    hr {
        margin-left: -40px;
        margin-right: -40px;
        width: calc(100% + 80px);
        border-width: 0;
        border-top: 1px solid #c4cae0;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletL}) {
        padding: 30px 20px;

        hr {
            margin-left: -20px;
            margin-right: -20px;
            width: calc(100% + 40px);
        }
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        padding: 20px 15px;

        hr {
            margin-left: -15px;
            margin-right: -15px;
            width: calc(100% + 30px);
        }
    }
`;


import Skeleton from "react-loading-skeleton";
import {
    DoctorInfoSkeletonWrapper,
    DoctorItemSkeletonContainer,
    HorizontalRule,
    DoctorImageSkeleton,
    ScheduleSkeletonWrapper,
    TextLineSkeleton,
    TextLineSkeletonBlock,
} from "./DoctorItemSkeleton.styled";

export const DoctorItemSkeleton = (): React.ReactElement => {
    return (
        <DoctorItemSkeletonContainer>
            <DoctorInfoSkeletonWrapper>
                <DoctorImageSkeleton />
                <TextLineSkeletonBlock>
                    <Skeleton width="70%" height="28px" />
                    <Skeleton height="28px" />
                    <Skeleton width="50%" height="28px" />
                    <Skeleton width="30%" height="28px" />
                </TextLineSkeletonBlock>
            </DoctorInfoSkeletonWrapper>

            <HorizontalRule></HorizontalRule>

            <ScheduleSkeletonWrapper>
                <TextLineSkeletonBlock>
                    <Skeleton count={4} height="28px" />
                </TextLineSkeletonBlock>
            </ScheduleSkeletonWrapper>
        </DoctorItemSkeletonContainer>
    );
};


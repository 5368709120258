import { UserProfileFormErrors, UserProfileState } from "stores/types/userTypes";

export class UserConverter {
    convertUserProfileToState = (data: any): UserProfileState => {
        return {
            tosAreAgreed: data.tos_are_agreed,
            registered: data.registered,
            phone: data.phone,
            firstName: data.first_name,
            lastName: data.last_name,
        };
    };

    convertUserProfileErrors = (data: any): UserProfileFormErrors => {
        let convertData: UserProfileFormErrors = {};

        for (let key in data) {
            switch (true) {
                case key === "first_name": {
                    convertData = { ...convertData, ...{ firstName: "Пожалуйста, проверьте имя." } };
                    break;
                }
                case key === "last_name": {
                    convertData = { ...convertData, ...{ lastName: "Пожалуйста, проверьте фамилию." } };
                    break;
                }
                case key === "tos_are_agreed": {
                    convertData = { ...convertData, ...{ tosAreAgreed: "Пожалуйста, примите соглашение." } };
                    break;
                }
                default: {
                    convertData = { ...convertData, ...{ profile: "Пожалуйста, проверьте данные и повторите отправку." } };
                    break;
                }
            }
        }

        return convertData;
    };
}


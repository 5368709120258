import { useOutsideClick } from "hooks/useOutsideClick";
import { memo, useEffect, useRef, useState } from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownList,
    DropdownListWrapper,
    DropdownWrapper,
    EmptyDropdownItem,
    SelectWrapper,
    StyledInput,
    UpDownIcon,
} from "./Select.styled";

export type SelectItem = {
    id: number | string;
    value: string;
};

type SelectType = {
    values: SelectItem[];
    valueId: number | string;
    label?: string;
    className?: string;
    onChange: (id?: number | string, value?: string) => void;
};

const SelectContainer = (props: SelectType) => {
    const { values, valueId, className, label, onChange } = props;

    const wrapperRef = useRef<any>(null);
    const [inputValue, setInputValue] = useState("");
    const [opened, setOpened] = useState(false);

    const handleClickInput = () => {
        setOpened((prev) => !prev);
    };

    useOutsideClick(wrapperRef, () => setOpened(false), opened);

    const handleClickItem = (value: SelectItem) => {
        // setInputValue(value.value);
        onChange(value.id, value.value);
        setOpened(false);
    };

    const handleClickEmptyItem = () => {
        // setInputValue('');
        onChange();
        setOpened(false);
    };

    useEffect(() => {
        const val = values.find((x) => x.id === valueId);
        setInputValue(val ? val.value : "");
    }, [valueId, values]);

    return (
        <SelectWrapper ref={wrapperRef} className={className}>
            <StyledInput
                label={label}
                value={inputValue}
                icon={<UpDownIcon $opened={opened} />}
                onIconClick={handleClickInput}
                onClick={handleClickInput}
                readOnly={true}
            />

            <DropdownWrapper $opened={opened}>
                <Dropdown>
                    <DropdownListWrapper>
                        <DropdownList>
                            <EmptyDropdownItem onClick={() => handleClickEmptyItem()}>Показать всех</EmptyDropdownItem>
                            {values.map((x, i) => (
                                <DropdownItem key={i} onClick={() => handleClickItem(x)}>
                                    {x.value}
                                </DropdownItem>
                            ))}
                        </DropdownList>
                    </DropdownListWrapper>
                </Dropdown>
            </DropdownWrapper>
        </SelectWrapper>
    );
};

export const Select = memo(SelectContainer);


import React, { Component } from "react";
import { Layout } from "./common/Layout";

type ErrorBoundaryPropsType = {
    children: React.ReactNode;
};

type ErrorBoundaryStateType = {
    hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryPropsType, ErrorBoundaryStateType> {
    constructor(props: ErrorBoundaryPropsType) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): ErrorBoundaryStateType {
        return { hasError: true };
    }

    render(): React.ReactNode {
        const { children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return (
                <Layout>
                    <div style={{ textAlign: "center" }}>
                        <h1>Что то пошло не так.</h1>
                        <p>Обновите страницу.</p>
                    </div>
                </Layout>
            );
        }

        return children;
    }
}

export default ErrorBoundary;


import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./components/App";
import { Provider } from "react-redux";
import { store } from "stores/store";
// import reportWebVitals from './reportWebVitals';
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { history } from "stores/store";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            {/* <BrowserRouter> */}
            <Router history={history}>
                <App />
            </Router>
            {/* </BrowserRouter> */}
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import styled from "@emotion/styled";

export const ModalWrapper = styled.div`
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        align-items: end;
    }
`;

export const ContentWrapper = styled.div`
    position: relative;
    width: 90vw;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    padding: 45px 40px 40px 40px;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 30px 20px 20px 20px;
        width: 100vw;
        max-width: 100vw;
    }
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    padding: 0;
    width: fit-content;
    cursor: pointer;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        top: 10px;
        right: 10px;
    }

    svg {
        fill: #3a3b41;
        width: 24px;
        height: 24px;

        @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
            width: 20px;
            height: 20px;
        }
    }
`;


export const formatWeekday = (timestamp: number) => {
    const date = new Date(timestamp);
    const dayInt = date.getDay();
    const weekday =
        dayInt === 0
            ? "Воскресенье"
            : dayInt === 1
            ? "Понедельник"
            : dayInt === 2
            ? "Вторник"
            : dayInt === 3
            ? "Среда"
            : dayInt === 4
            ? "Четверг"
            : dayInt === 5
            ? "Пятница"
            : "Суббота";
    return `${weekday}`;
};

export const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    const day = date.toLocaleDateString("ru-RU", { month: "long", day: "numeric" });
    const time = date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2);
    return `${day} в ${time}`;
};


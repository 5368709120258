import styled from "@emotion/styled";
import { ElementContainer } from "components/ui/ElementContainer";

type BranchProps = {
    $active: boolean;
};

export const transientOptions = {
    shouldForwardProp: (propName: string) => !propName.startsWith("$"),
};

export const BranchSelectWrapper = styled(ElementContainer)`
    display: flex;
    align-items: center;
    gap: 10px 30px;
    flex-wrap: wrap;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-flow: wrap;
    gap: 10px;
    align-items: center;
    max-width: 100%;
`

export const BranchSelectItemWrapper = styled('div', transientOptions)<BranchProps>`
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-self: stretch;
    cursor: pointer;
    padding: 17px 17px;
    width: calc(450px - 34px);
    max-width: 100%;
    border-radius: 5px;
    border: 2px solid ${({ $active }) => $active ? '#2c41ff' : '#C4CAE0'} ;

    &:hover {
        border: 2px solid #3500CB;
    }
`;

export const BranchTitle = styled.div`

`

export const BranchAddress = styled.div`
    color: #838383;
`
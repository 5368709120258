import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/store";
import {
    ContainerDoctorList,
    HorizontalAppointmentTypeButtonGroup,
    InfoContent,
    InfoImage,
    InfoParagraph,
    InformationContainer,
    NextPageButton,
    NoDoctorContainer,
    NotificationContent,
    NotificationIcon,
    PaymentMessageContainer,
    PersonSearchIcon,
    VerticalAppointmentTypeButtonGroup,
} from "./DoctorList.styled";
import { MyAppointments } from "./components/MyAppointments";
import { BlockWrapper, Header2, Header1, ResponseContainer } from "assets/styles/global.styled";
import { DoctorItem } from "./components/DoctorItem";
import { AppointmentType, FetchStatus } from "entities/Enums";
import { clearDoctorListState, fetchBranchesSaga, fetchDoctorListSaga, fetchSpecializationsSaga } from "stores/doctorsSlice";
import { useSearchParams } from "react-router-dom";
import { fetchLastAppointmentSaga } from "stores/appointmentsSlice";
import { DoctorItemSkeleton } from "./components/DoctorItemSkeleton";
import { BranchSelect } from "components/pages/DoctorList/components/BranchSelect";
import { SpecializationSelect } from "./components/SpecializationSelect";

const FIRST_PAGE = 1;

enum PaymentStatusType {
    None = "none",
    Success = "success",
    Fail = "fail",
}

export const DoctorList = (): React.ReactElement => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const { token } = useSelector((state: RootState) => state.user);
    const { appointments } = useSelector((state: RootState) => state.appointments);
    const { doctors, specializations, branches } = useSelector((state: RootState) => state.doctors);
    const {
        config: {
            data: { enabledAppointments },
        },
    } = useSelector((state: RootState) => state.application);

    const appointmentValues = {
        [AppointmentType.Inside]: "В клинике",
        [AppointmentType.Online]: "Онлайн прием",
        [AppointmentType.Outside]: "Выезд на дом",
    };

    const enabledAppointmentKeys = useMemo(
        () => Object.keys(appointmentValues).filter((x) => x in enabledAppointments && enabledAppointments[x as AppointmentType]),
        [enabledAppointments]
    );

    const appointmentButtonValues = useMemo(
        () => enabledAppointmentKeys.map((x, i) => ({ id: i, value: appointmentValues[x as AppointmentType] })),
        [enabledAppointments]
    );

    const defaultActiveAppType = useMemo(() => {
        const typeParam = searchParams.get("type");
        if (typeParam && typeParam in enabledAppointments && enabledAppointments[typeParam as AppointmentType]) {
            return typeParam;
        }
        return enabledAppointmentKeys[0];
    }, []);

    const defaultSpecizalizationSlug = useMemo(() => {
        const spec = searchParams.get("spec");
        return spec ?? "";
    }, []);

    const defaultActiveBranchId = useMemo(() => {
        const branchIdStr = searchParams.get("branch");
        return branchIdStr ? parseInt(branchIdStr) : -1;
    }, []);

    const paymentStatus = useMemo(() => {
        return searchParams.get("payment") ?? PaymentStatusType.None;
    }, []);

    const errType = useMemo(() => {
        return searchParams.get('err') ?? undefined;
    }, [])

    const [activeAppointmentType, setActiveAppointmentType] = useState(defaultActiveAppType);
    const [activeBranchId, setActiveBranchId] = useState(defaultActiveBranchId);
    const [activeSpecSlug, setActiveSpecSlug] = useState(defaultSpecizalizationSlug);
    const [currentPage, setCurrentPage] = useState(FIRST_PAGE);

    const activeAppointmentTypeId = useMemo(
        () => enabledAppointmentKeys.findIndex((x) => x === activeAppointmentType),
        [activeAppointmentType]
    );

    const fetchDoctorListParams = useMemo(() => {
        return {
            online: activeAppointmentType === AppointmentType.Online ? true : undefined,
            inside: activeAppointmentType === AppointmentType.Inside ? true : undefined,
            outside: activeAppointmentType === AppointmentType.Outside ? true : undefined,
            spec: activeSpecSlug ?? undefined,
            branch: activeBranchId !== -1 ? activeBranchId : undefined,
        };
    }, [activeAppointmentType, activeSpecSlug, activeBranchId]);

    const updateDoctorList = (page: number, forceUpdateCache: boolean = false) => {
        const offset = page > FIRST_PAGE && doctors.data.results.length ? doctors.data.results.length : undefined;

        if (!offset) {
            dispatch(clearDoctorListState());
        }

        dispatch(
            fetchDoctorListSaga({
                filter: { ...fetchDoctorListParams, offset: offset },
                forceUpdateCache,
            })
        );
    };

    useEffect(() => {
        const timeDelay = 10 * 60 * 1000;
        const getDoctors = setInterval(() => {
            updateDoctorList(currentPage, true);
        }, timeDelay);

        return () => clearInterval(getDoctors);
    }, []);

    useEffect(() => {
        dispatch(fetchSpecializationsSaga());
        dispatch(fetchBranchesSaga());
        if (token) {
            dispatch(fetchLastAppointmentSaga());
        }
    }, []);

    useEffect(() => {
        updateDoctorList(FIRST_PAGE);
        setCurrentPage(FIRST_PAGE);
    }, [activeAppointmentType, activeSpecSlug, activeBranchId]);

    useEffect(() => {
        let params = { type: activeAppointmentType } as any;
        if (activeSpecSlug) {
            params.spec = activeSpecSlug;
        }
        if (activeBranchId != -1) {
            params.branch = activeBranchId;
        }
        setSearchParams(params);
    }, [activeAppointmentType, activeSpecSlug, activeBranchId, paymentStatus]);

    useEffect(() => {
        if (specializations.loading !== FetchStatus.Complete) return;
        if (!specializations.data.map((x) => x.slug).includes(activeSpecSlug)) {
            setActiveSpecSlug("");
        }
    }, [specializations]);

    useEffect(() => {
        if (branches.loading !== FetchStatus.Complete) return;
        if (!branches.data.map((x) => x.id).includes(activeBranchId)) {
            setActiveBranchId(branches.data.length ? branches.data[0].id : -1);
        }
    }, [branches]);

    const handleClickAppointmentType = (id: number) => {
        setActiveAppointmentType(enabledAppointmentKeys[id]);
    };

    const handleClickNextPage = () => {
        updateDoctorList(currentPage + 1);
        setCurrentPage((prev) => prev + 1);
    };

    const handleChangeBranch = (id?: number | string, value?: string) => {
        setActiveBranchId(id ? (id as number) : -1);
    };

    const handleChangeSpecialization = (slug: string) => {
        setActiveSpecSlug(slug);
    };

    return (
        <ContainerDoctorList>
            <ResponseContainer>
                {paymentStatus === PaymentStatusType.Success && (
                    <PaymentMessageContainer>
                        <NotificationIcon />
                        <NotificationContent>Оплата прошла успешно</NotificationContent>
                    </PaymentMessageContainer>
                )}

                {paymentStatus === PaymentStatusType.Fail && (
                    <PaymentMessageContainer $isError={true}>
                        <NotificationIcon />
                        <NotificationContent>Произошла ошибка при оплате, попробуйте повторить.</NotificationContent>
                    </PaymentMessageContainer>
                )}

                {errType === 'has_appointment' && (
                    <PaymentMessageContainer $isError={true}>
                        <NotificationIcon />
                        <NotificationContent>У вас уже есть забронированная запись. Вы можете ее отменить, чтобы записаться на другое время.</NotificationContent>
                    </PaymentMessageContainer>
                )}

                {!!token && !!appointments.length && <MyAppointments></MyAppointments>}

                <BlockWrapper>
                    <Header1>Запись к врачу</Header1>

                    <HorizontalAppointmentTypeButtonGroup
                        styleType="horizontal"
                        values={appointmentButtonValues}
                        activeValueId={activeAppointmentTypeId}
                        onClick={handleClickAppointmentType}
                    />

                    <VerticalAppointmentTypeButtonGroup
                        styleType="vertical"
                        values={appointmentButtonValues}
                        activeValueId={activeAppointmentTypeId}
                        onClick={handleClickAppointmentType}
                    />

                    {activeAppointmentType === "inside" && <BranchSelect activeBranchId={activeBranchId} onClick={handleChangeBranch} />}

                    <SpecializationSelect activeSpecializationSlug={activeSpecSlug} onChange={handleChangeSpecialization} />

                    {doctors.loading === FetchStatus.Fetching && !doctors.data.results.length && <DoctorItemSkeleton />}

                    {doctors.loading !== FetchStatus.Fetching && !doctors.data.results.length && (
                        <NoDoctorContainer>
                            <PersonSearchIcon />
                            <p>Список докторов по заданным фильтрам пуст.</p>
                        </NoDoctorContainer>
                    )}

                    {doctors.loading === FetchStatus.Complete &&
                        doctors.data.results.map((doctor, i) => (
                            <DoctorItem key={doctor.slug + doctor.schedule.id} doctor={doctor}></DoctorItem>
                        ))}

                    {doctors.data.count > doctors.data.results.length && (
                        <NextPageButton onClick={handleClickNextPage} loading={doctors.loading}>
                            Показать еще
                        </NextPageButton>
                    )}
                </BlockWrapper>

                <BlockWrapper>
                    <InformationContainer>
                        <InfoContent>
                            <Header2>Как подготовиться к онлайн-консультации</Header2>
                            <InfoParagraph>
                                Найдите тихое место cо стабильным интернетом. Если нужно наглядно показать проблему, подготовьте фото или
                                видео заранее. Файлы вы сможете отправить врачу на звонке.
                            </InfoParagraph>

                            <Header2>Как проходят онлайн-консультации</Header2>
                            <InfoParagraph>
                                Расскажите доктору, что вас беспокоит. Если возможно, прикрепите фото или видео. Врач задаст дополнительные
                                вопросы и по итогу даст рекомендации. В среднем консультация занимает 15 минут. Если связь прервётся, врач
                                перезвонит вам на мобильный.
                            </InfoParagraph>
                        </InfoContent>

                        <InfoImage />
                    </InformationContainer>
                </BlockWrapper>
            </ResponseContainer>
        </ContainerDoctorList>
    );
};


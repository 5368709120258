import { memo, useEffect } from "react";
import { ButtonSubmitForm, FormContainer, ResponseContainerForm, SubtitleForm, TitleForm, ErrorField } from "../Forms.styled";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { RootState } from "stores/store";
import { ChangePhoneLink, ConfirmationContainer, HelperTextForm, HelperTextPhone, LastNameContainer } from "./FormSendProfile.styled";
import { ControllerName } from "../controllers/ControllerName";
import { ControllerCheckbox } from "../controllers/ControllerCheckbox";
import { patternRussianName } from "utils/validation";
import {
    clearAuthorizationState,
    registerProfileSaga,
    setAuthorizationLoadingState,
    setFieldErrorMessage,
} from "stores/authorizationSlice";
import { logout } from "stores/userSlice";
import { FetchStatus } from "entities/Enums";
import { UserProfileState } from "stores/types/userTypes";

const FormSendProfileContainer = () => {
    const dispatch = useDispatch();
    const { loading, error, fieldErrors } = useSelector((state: RootState) => state.authorization);
    const { profile } = useSelector((state: RootState) => state.user);

    const { handleSubmit, watch, reset, control } = useForm<UserProfileState>({
        mode: "onChange",
        defaultValues: {
            firstName: profile.data.firstName,
            lastName: profile.data.lastName,
            tosAreAgreed: false,
        },
    });

    useEffect(() => {
        dispatch(setAuthorizationLoadingState({ loading: FetchStatus.None }));
    }, []);

    const onSubmit = (data: UserProfileState) => {
        dispatch(setFieldErrorMessage({}));
        dispatch(registerProfileSaga(data));
    };

    const { firstName, lastName, tosAreAgreed } = watch();

    const filled = () => {
        return (
            !!firstName && patternRussianName.value.test(firstName) && !!lastName && patternRussianName.value.test(lastName) && tosAreAgreed
        );
    };

    const handleClickChangePhoneBtn = () => {
        dispatch(clearAuthorizationState());
        dispatch(logout());
    };

    useEffect(() => {
        dispatch(setAuthorizationLoadingState({ loading: FetchStatus.None }));
    }, []);

    return (
        <ResponseContainerForm>
            <TitleForm>Заполнение профиля</TitleForm>

            <SubtitleForm>Введите ваши данные</SubtitleForm>

            <FormContainer onSubmit={handleSubmit(onSubmit)}>
                <ControllerName
                    name="firstName"
                    control={control}
                    label="Ваше имя"
                    autoFocus={true}
                    error={fieldErrors?.firstName}
                    loading={loading}
                />
                <LastNameContainer>
                    <ControllerName
                        name="lastName"
                        control={control}
                        label="Ваша фамилия"
                        error={fieldErrors?.lastName}
                        loading={loading}
                    />
                </LastNameContainer>

                <ConfirmationContainer>
                    <ControllerCheckbox
                        name="tosAreAgreed"
                        control={control}
                        defaultValue={false}
                        loading={loading}
                        error={fieldErrors?.tosAreAgreed}
                    />
                </ConfirmationContainer>

                <ErrorField>{error}</ErrorField>

                <ButtonSubmitForm type="submit" loading={loading} disabled={!filled()}>
                    Продолжить
                </ButtonSubmitForm>

                <HelperTextForm>
                    Вы авторизовались по номеру <HelperTextPhone>{profile.data.phone}</HelperTextPhone>
                    <p>
                        <ChangePhoneLink title="Изменить" url="" onClick={handleClickChangePhoneBtn} />
                    </p>
                </HelperTextForm>
            </FormContainer>
        </ResponseContainerForm>
    );
};

export const FormSendProfile = memo(FormSendProfileContainer);


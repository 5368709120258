import { STORAGE_URL } from "constants/customInfo";
import { AppointmentType } from "entities/Enums";
import {
    DoctorItemCacheState,
    DoctorListCacheState,
    ScheduleIntervalState,
    ScheduleState,
    SpecializationState,
} from "stores/types/doctorsTypes";
import { isArray } from "utils/utils";

export const convertSpecializationToState = (data: any): SpecializationState => {
    const { slug, title, order } = data;
    return { slug, title, order };
};

export class DoctorListConverter {
    convertScheduleIntervalToState = (data: any): ScheduleIntervalState => {
        const { start_datetime } = data;
        return { startDatetime: start_datetime * 1000 };
    };

    convertScheduleToState = (data: any): ScheduleState => {
        const { id, price, room, reseption_type, branch } = data;

        const appointmentType =
            reseption_type === "online"
                ? AppointmentType.Online
                : reseption_type === "inside"
                ? AppointmentType.Inside
                : AppointmentType.Outside;

        const intervals = isArray(data.intervals)
            ? data.intervals
                  .map(this.convertScheduleIntervalToState)
                  .sort((a: ScheduleIntervalState, b: ScheduleIntervalState) =>
                      a.startDatetime < b.startDatetime ? -1 : a.startDatetime > b.startDatetime ? 1 : 0
                  )
            : [];

        return { id, price, room, appointmentType, branch, intervals };
    };

    convertDoctorItemToState = (data: any): DoctorItemCacheState => {
        const { slug, first_name, last_name, middle_name, photo, work_period, primary_specialization } = data;

        const schedules: ScheduleState[] = data.schedules.map((doctor: any) => this.convertScheduleToState(doctor));

        return {
            slug,
            name: `${last_name} ${first_name} ${middle_name}`,
            photo: new URL(photo, STORAGE_URL).href,
            workPeriod: work_period,
            schedules,
            specialization: convertSpecializationToState(primary_specialization),
        };
    };

    convertDoctorListToCacheState = (data: any): DoctorListCacheState => {
        const { count } = data;
        return {
            count,
            results: isArray(data.results) ? data.results.map(this.convertDoctorItemToState) : [],
        };
    };
}


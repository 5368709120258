import { ActionWithPayloadType, HTTPMethodType } from "entities/Redux";
import { put, takeLatest } from "redux-saga/effects";
import { createActionType, createCommonRequest } from "./shared";
import { ConfigConverter } from "./converters/configConverter";
import { FetchStatus } from "entities/Enums";
import { fetchConfigSaga, setConfig, setConfigLoadingState } from "stores/applicationSlice";

export const { GET, POST, PUT, DEL } = HTTPMethodType;

const fetchConfigSagaActions = createActionType(fetchConfigSaga.toString());

function* fetchConfigWorker() {
    yield put(setConfigLoadingState({ loading: FetchStatus.None }));

    const fetchConfigRequest = createCommonRequest(fetchConfigSaga.toString(), GET, "account/patient/info/");
    yield fetchConfigRequest();
}

function* fetchConfigStartWorker() {
    yield put(setConfigLoadingState({ loading: FetchStatus.Fetching }));
}

function* fetchConfigSuccessWorker({ payload }: ActionWithPayloadType<any>) {
    const convertConfig = new ConfigConverter().convertConfig(payload.data.config);
    yield put(setConfig(convertConfig));
    yield put(setConfigLoadingState({ loading: FetchStatus.Complete }));
}

function* fetchConfigFailWorker() {
    yield put(setConfigLoadingState({ loading: FetchStatus.Fail }));
}

export function* applicationWatcher() {
    yield takeLatest(fetchConfigSagaActions.name, fetchConfigWorker);
    yield takeLatest(fetchConfigSagaActions.start, fetchConfigStartWorker);
    yield takeLatest(fetchConfigSagaActions.success, fetchConfigSuccessWorker);
    yield takeLatest(fetchConfigSagaActions.fail, fetchConfigFailWorker);
}

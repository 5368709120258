import React from "react";
import { commonRules } from "utils/validation";
import { Input } from "components/ui/Input";
import { Controller } from "react-hook-form";
import { ControllerType } from "entities/Forms";

type ControllerLineTextProps = ControllerType & {
    label: string;
    centerText?: boolean;
    maxLength?: number;
    autoFocus?: boolean;
    disabled?: boolean;
};

export const ControllerLineText: React.FC<ControllerLineTextProps> = (props) => {
    const { name, control, error, label, autoFocus = false, disabled, loading, centerText = false, maxLength = 250 } = props;

    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            rules={{
                ...commonRules,
            }}
            render={({ field: { onChange, onBlur, value }, fieldState }) => {
                const errorMessage = fieldState?.error?.message;

                return (
                    <Input
                        label={label}
                        centerText={centerText}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        error={errorMessage || error}
                        autoFocus={autoFocus}
                        loading={loading}
                        disabled={disabled}
                        maxLength={maxLength}
                    />
                );
            }}
        />
    );
};


import React, { memo } from "react";
import { NotificationContainer, NotificationText } from "./FloatingNotification.styled";

type FloatingNotificationProps = {
    text: string;
    visibility: boolean;
};

const FloatingNotificationContainer: React.FC<FloatingNotificationProps> = (props) => {
    const { text, visibility } = props;

    return (
        <NotificationContainer $visibility={visibility}>
            <NotificationText>{text}</NotificationText>
        </NotificationContainer>
    );
};

export const FloatingNotification: React.FC<FloatingNotificationProps> = memo(FloatingNotificationContainer);


import { fork } from "redux-saga/effects";
import { applicationWatcher } from "./applicationSaga";
import { appointmentsWatcher } from "./appointmentsSaga";
import { authorizationWatcher } from "./authorizationSaga";
import { doctorsWatcher } from "./doctorsSaga";
import { userWatcher } from "./userSaga";

export function* rootWatcher() {
    yield fork(applicationWatcher);
    yield fork(doctorsWatcher);
    yield fork(authorizationWatcher);
    yield fork(userWatcher);
    yield fork(appointmentsWatcher);
}


import { CLINIC_PHONE, CLINIC_PHONE_URL, PRIVACY_POLICY_LINK, USER_AGREEMENT_LINK } from "constants/customInfo";
import React, { memo } from "react";
import { FooterContainer, FooterResponseContainer, NavbarLink, PhoneLink } from "./MainFooter.styles";
import { ReactComponent as PhoneSvg } from "assets/images/phone.svg";

const MainFooterContainer: React.FC = () => {
    return (
        <FooterContainer>
            <FooterResponseContainer>
                <PhoneLink title={CLINIC_PHONE} url={CLINIC_PHONE_URL} icon={<PhoneSvg />} type="outside" />

                <NavbarLink title="Пользовательское соглашение" url={USER_AGREEMENT_LINK} target="_blank" />
                <NavbarLink title="Политика конфиденциальности" url={PRIVACY_POLICY_LINK} target="_blank" />
            </FooterResponseContainer>
        </FooterContainer>
    );
};

export const MainFooter: React.FC = memo(MainFooterContainer);


import { ActionWithPayloadType, HTTPMethodType } from "entities/Redux";
import { put, takeLatest } from "redux-saga/effects";
import { fetchUserProfileSaga, setUserProfile, setUserProfileLoading } from "stores/userSlice";
import { UserConverter } from "./converters/userConverter";
import { createActionType, createCommonRequest } from "./shared";
import { FetchStatus } from "entities/Enums";

export const { GET, POST, PUT, DEL } = HTTPMethodType;

const fetchUserProfileSagaActions = createActionType(fetchUserProfileSaga.toString());

function* fetchUserProfileWorker() {
    yield put(setUserProfileLoading({ loading: FetchStatus.None }));

    const fetchUserProfileRequest = createCommonRequest(fetchUserProfileSagaActions.name, GET, "account/patient/iam/");
    yield fetchUserProfileRequest();
}

function* fetchUserProfileStartWorker() {
    yield put(setUserProfileLoading({ loading: FetchStatus.Fetching }));
}

function* fetchUserProfileSuccessWorker({ payload }: ActionWithPayloadType<any>) {
    const userState = new UserConverter().convertUserProfileToState(payload.data);
    yield put(setUserProfile(userState));
    yield put(setUserProfileLoading({ loading: FetchStatus.Complete }));
}

function* fetchUserProfileFailWorker() {
    yield put(setUserProfileLoading({ loading: FetchStatus.Fail }));
}

export function* userWatcher() {
    yield takeLatest(fetchUserProfileSagaActions.name, fetchUserProfileWorker);
    yield takeLatest(fetchUserProfileSagaActions.start, fetchUserProfileStartWorker);
    yield takeLatest(fetchUserProfileSagaActions.success, fetchUserProfileSuccessWorker);
    yield takeLatest(fetchUserProfileSagaActions.fail, fetchUserProfileFailWorker);
}

import {
    YM_CLIENT_APPOINTMENT_CREATION_SUCCESS,
    YM_CLIENT_COUNTER_ID,
    YM_COMMON_APPOINTMENT_CREATION_SUCCESS,
    YM_COMMON_COUNTER_ID,
} from "constants/customInfo";
import { DoctorFiltrationType, FetchStatus } from "entities/Enums";
import { ActionWithPayloadType, HTTPMethodType } from "entities/Redux";
import { push } from "redux-first-history";
import { call, put, takeLatest, select } from "redux-saga/effects";
import {
    addDoctorsToList,
    bookAppointmentSaga,
    fetchDoctorListSaga,
    fetchSpecializationsSaga,
    fetchBranchesSaga,
    setBookFormLoadingState,
    setDoctorList,
    setDoctorListCache,
    setDoctorListLoadingState,
    setSpecializations,
    setBranches,
    setSpecializationsLoadingState,
    setBranchesLoadingState,
} from "stores/doctorsSlice";
import { BookAppointmentProps, DoctorListFilter, DoctorListCacheState, DoctorListViewState } from "stores/types/doctorsTypes";
import YaMetrika from "utils/yametrika";
import { convertSpecializationToState, DoctorListConverter } from "./converters/doctorConverter";
import { DoctorCacheListHelper } from "./helpers/DoctorCacheListHelper";
import { createActionType, createCommonRequest } from "./shared";

export const { GET, POST, PUT, DEL } = HTTPMethodType;

const fetchDoctorListSagaActions = createActionType(fetchDoctorListSaga.toString());
const fetchSpecializationsSagaActions = createActionType(fetchSpecializationsSaga.toString());
const fetchBranchesSagaActions = createActionType(fetchBranchesSaga.toString());
const bookAppointmentSagaActions = createActionType(bookAppointmentSaga.toString());

function* fetchDoctorCacheList(filter: DoctorListFilter) {
    const { doctorsCache } = yield select((state) => state.doctors);
    const doctorCacheArr = new DoctorCacheListHelper(doctorsCache.results).filter(filter).get();
    const doctorCacheList: DoctorListCacheState = {
        count: doctorCacheArr.length,
        results: doctorCacheArr,
    };

    yield put({
        type: fetchDoctorListSagaActions.success,
        payload: {
            data: doctorCacheList,
            options: { filter, useCache: true },
        },
    });
}

function* fetchDoctorListWorker({ payload }: ActionWithPayloadType<{ filter: DoctorListFilter; forceUpdateCache: boolean }>) {
    const { filter, forceUpdateCache } = payload;
    const { doctorsCache } = yield select((state) => state.doctors);
    const {
        config: {
            data: { enabledAppointments, doctorsFiltrationType },
        },
    } = yield select((state) => state.application);

    const fetchDoctorListRequest = createCommonRequest(fetchDoctorListSaga.toString(), GET, "doctors/");

    if (doctorsFiltrationType === DoctorFiltrationType.Front) {
        if (doctorsCache.count === 0 || forceUpdateCache) {
            const filterParam = {
                ...enabledAppointments,
                mode: true,
                limit: 100,
            };
            yield fetchDoctorListRequest(filterParam, { filter, forceUpdateCache: true });
        } else {
            yield fetchDoctorCacheList(filter);
        }
    } else {
        const filterParam = { ...filter, mode: false };
        yield fetchDoctorListRequest(filterParam, { filter });
    }
}

function* fetchDoctorListStartWorker() {
    yield put(setDoctorListLoadingState({ loading: FetchStatus.Fetching }));
}

function* fetchDoctorListSuccessWorker({
    payload,
}: ActionWithPayloadType<{
    data: any;
    options: { filter: DoctorListFilter; forceUpdateCache: boolean; useCache: boolean };
    params: DoctorListFilter;
}>) {
    const {
        data: doctors,
        options: { filter, forceUpdateCache, useCache },
        params: filterParam,
    } = payload;

    if (doctors) {
        const doctorCacheList = useCache
            ? (doctors as DoctorListCacheState)
            : new DoctorListConverter().convertDoctorListToCacheState(doctors);
        const doctorsViewArr = new DoctorCacheListHelper(doctorCacheList.results).filter(filter).getView(filter);

        const doctorsViewList: DoctorListViewState = {
            count: doctorsViewArr.length,
            results: doctorsViewArr,
        };

        if (filterParam && filterParam.offset) {
            yield put(addDoctorsToList(doctorsViewList));
        } else {
            yield put(setDoctorList(doctorsViewList));
        }

        if (forceUpdateCache) {
            yield put(setDoctorListCache(doctorCacheList));
        }
    }
    yield put(setDoctorListLoadingState({ loading: FetchStatus.Complete }));
}

function* fetchDoctorListFailWorker() {
    yield put(setDoctorListLoadingState({ loading: FetchStatus.Fail })); // todo: add error message
}

function* fetchSpecializationsWorker() {
    const fetchSpecializationsRequest = createCommonRequest(fetchSpecializationsSaga.toString(), GET, "doctors/specializations/");
    yield fetchSpecializationsRequest();
}

function* fetchSpecializationsStartWorker() {
    yield put(setSpecializationsLoadingState({ loading: FetchStatus.Fetching }));
}

function* fetchSpecializationsSuccessWorker({ payload }: ActionWithPayloadType<any>) {
    const specializations = payload.data.map(convertSpecializationToState);
    yield put(setSpecializations(specializations));
    yield put(setSpecializationsLoadingState({ loading: FetchStatus.Complete }));
}

function* fetchSpecializationsFailWorker() {
    yield put(setSpecializationsLoadingState({ loading: FetchStatus.Fail })); // todo: add error message
}

function* fetchBranchesWorker() {
    const fetchBranchesRequest = createCommonRequest(fetchBranchesSaga.toString(), GET, "doctors/branches/");
    yield fetchBranchesRequest();
}

function* fetchBranchesStartWorker() {
    yield put(setBranchesLoadingState({ loading: FetchStatus.Fetching }));
}

function* fetchBranchesSuccessWorker({ payload }: ActionWithPayloadType<any>) {
    yield put(setBranches(payload.data));
    yield put(setBranchesLoadingState({ loading: FetchStatus.Complete }));
}

function* fetchBranchesFailWorker() {
    yield put(setBranchesLoadingState({ loading: FetchStatus.Fail })); // todo: add error message
}

function* bookAppointmentWorker({ payload }: ActionWithPayloadType<BookAppointmentProps>) {
    const bookAppointmentRequest = createCommonRequest(bookAppointmentSaga.toString(), POST, "account/patient/appointments/book/");
    yield bookAppointmentRequest({
        schedule_id: payload.scheduleId,
        start_timestamp: Math.floor(payload.startTimestamp / 1000), // ms to seconds
        address: payload.address,
    });
}

function* bookAppointmentStartWorker() {
    yield put(setBookFormLoadingState({ loading: FetchStatus.Fetching }));
}

function* bookAppointmentSuccessWorker({ payload }: ActionWithPayloadType<any>) {
    const reachGoalPromise = (counterId?: number, goalName?: string) =>
        new Promise<void>((resolve) => {
            if (counterId && goalName) {
                new YaMetrika(counterId).reachGoal(goalName, () => {
                    resolve();
                });
            } else {
                resolve();
            }
        });
    yield reachGoalPromise(YM_COMMON_COUNTER_ID, YM_COMMON_APPOINTMENT_CREATION_SUCCESS);
    yield reachGoalPromise(YM_CLIENT_COUNTER_ID, YM_CLIENT_APPOINTMENT_CREATION_SUCCESS);

    if (payload.data["is_free"] === false) {
        yield put(push(payload.data["payment_url"]));
    } else {
        yield call(() => {
            window.location.reload();
        });
    }
    yield put(setBookFormLoadingState({ loading: FetchStatus.Complete }));
}

function* bookAppointmentFailWorker() {
    yield put(setBookFormLoadingState({ loading: FetchStatus.Fail })); // todo: add error message
}

export function* doctorsWatcher() {
    yield takeLatest(fetchDoctorListSagaActions.name, fetchDoctorListWorker);
    yield takeLatest(fetchDoctorListSagaActions.start, fetchDoctorListStartWorker);
    yield takeLatest(fetchDoctorListSagaActions.success, fetchDoctorListSuccessWorker);
    yield takeLatest(fetchDoctorListSagaActions.fail, fetchDoctorListFailWorker);

    yield takeLatest(fetchSpecializationsSagaActions.name, fetchSpecializationsWorker);
    yield takeLatest(fetchSpecializationsSagaActions.start, fetchSpecializationsStartWorker);
    yield takeLatest(fetchSpecializationsSagaActions.success, fetchSpecializationsSuccessWorker);
    yield takeLatest(fetchSpecializationsSagaActions.fail, fetchSpecializationsFailWorker);

    yield takeLatest(fetchBranchesSagaActions.name, fetchBranchesWorker);
    yield takeLatest(fetchBranchesSagaActions.start, fetchBranchesStartWorker);
    yield takeLatest(fetchBranchesSagaActions.success, fetchBranchesSuccessWorker);
    yield takeLatest(fetchBranchesSagaActions.fail, fetchBranchesFailWorker);

    yield takeLatest(bookAppointmentSagaActions.name, bookAppointmentWorker);
    yield takeLatest(bookAppointmentSagaActions.start, bookAppointmentStartWorker);
    yield takeLatest(bookAppointmentSagaActions.success, bookAppointmentSuccessWorker);
    yield takeLatest(bookAppointmentSagaActions.fail, bookAppointmentFailWorker);
}


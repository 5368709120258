import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const GlobalStyles = (theme: any) => css`
    body {
        min-width: 320px;
        font-size: ${theme.global.text.font.size.desktop};
        line-height: 1.4;
        color: ${theme.global.text.color};

        @media (max-width: ${theme.breakpoints.tablet}) {
            font-size: ${theme.global.text.font.size.mobile};
        }
    }
`;

export const H1 = styled.h1`
    display: block;
    line-height: 1.15;
    width: 100%;
    font-size: ${({ theme }) => theme.global.header.h1.font.size.desktop};
    font-weight: bold;
    color: ${({ theme }) => theme.global.header.color};

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: ${({ theme }) => theme.global.header.h1.font.size.mobile};
    }
`;

export const H2 = styled.h2`
    display: block;
    line-height: 1.15;
    width: 100%;
    font-size: ${({ theme }) => theme.global.header.h2.font.size.desktop};
    font-weight: bold;
    color: ${({ theme }) => theme.global.header.color};

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: ${({ theme }) => theme.global.header.h2.font.size.mobile};
    }
`;

export const Header1 = styled(H1)`
    margin-bottom: 30px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-bottom: 20px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        margin-bottom: 15px;
    }
`;

export const Header2 = styled(H2)`
    margin-bottom: 15px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-bottom: 15px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        margin-bottom: 10px;
    }
`;

export const BlockWrapper = styled.div`
    padding: 40px 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        padding: 25px 0;
    }
`;

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
`;

export const ResponseContainer = styled.div`
    max-width: 1000px;
    width: 90%;
    padding: 20px 0;
`;

export const HorizontalRule = styled.hr`
    margin-top: 30px;
    margin-bottom: 30px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;
import React, { useEffect } from "react";
import { ContainerAuth } from "./Authorization.styled";
import { ResponseContainerForm } from "components/common/Forms/Forms.styled";
import { FormSendPhone } from "components/common/Forms/FormSendPhone";
import { FormSendCode } from "components/common/Forms/FormSendCode";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/store";
import { FormSendProfile } from "components/common/Forms/FormSendProfile";
import { clearAuthorizationState } from "stores/authorizationSlice";

export const Authorization = (): React.ReactElement => {
    const dispatch = useDispatch();

    const { otpKey } = useSelector((state: RootState) => state.authorization);
    const {
        token,
        profile: { data: profile },
    } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        return () => {
            dispatch(clearAuthorizationState());
        };
    }, []);

    return (
        <ContainerAuth>
            <ResponseContainerForm>
                {!token && !otpKey ? (
                    <>
                        <FormSendPhone />
                    </>
                ) : !token ? (
                    <FormSendCode />
                ) : !profile.registered || !profile.tosAreAgreed || !profile.firstName || !profile.lastName ? (
                    <FormSendProfile />
                ) : (
                    <></>
                )}
            </ResponseContainerForm>
        </ContainerAuth>
    );
};


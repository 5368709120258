import React from "react";
import { Outlet } from "react-router";
import { MainWrapperContainer } from "./AuthWrapper.styles";
import { AuthFooter } from "./components/AuthFooter";

export function AuthWrapper(): React.ReactElement {
    return (
        <>
            <Outlet />
            <AuthFooter />
        </>
    );
}


import { ActionWithPayloadType, HTTPMethodType } from "entities/Redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { cancelAppointmentSaga, fetchLastAppointmentSaga, setAppointments, setCancelingLoadingState } from "stores/appointmentsSlice";
import { AppointmentConverter } from "./converters/appointmentConverter";
import { createActionType, createCommonRequest } from "./shared";
import { FetchStatus } from "entities/Enums";

export const { GET, POST, PUT, DEL } = HTTPMethodType;

const cancelAppointmentSagaActions = createActionType(cancelAppointmentSaga.toString());

function* fetchLastAppointmentWorker() {
    const fetchAppointmentsRequest = createCommonRequest(fetchLastAppointmentSaga.toString(), GET, "account/patient/appointments/");
    const { data } = yield fetchAppointmentsRequest({
        status: "booked",
        ordering: "start_datetime",
        limit: 1,
    });

    if (data && data.count > 0) {
        const appointmentState = new AppointmentConverter().convertAppointmentToState(data.results[0]);
        yield put(setAppointments([appointmentState]));
    }
    // todo: if data is empty
}

function* cancelAppointmentWorker({ payload }: ActionWithPayloadType<number>) {
    const id = payload;
    const cancelAppointmentRequest = createCommonRequest(
        cancelAppointmentSaga.toString(),
        PUT,
        `account/patient/appointments/${id}/cancel/`
    );
    yield cancelAppointmentRequest();
}

function* cancelAppointmentStartWorker() {
    yield put(setCancelingLoadingState({ loading: FetchStatus.Fetching }));
}

function* cancelAppointmentSuccessWorker() {
    const delay = (ms: number) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };
    yield call(delay, 3000); // wait while refund is done
    window.location.reload();
    yield put(setCancelingLoadingState({ loading: FetchStatus.Complete }));
}

function* cancelAppointmentFailWorker() {
    yield put(setCancelingLoadingState({ loading: FetchStatus.Fail })); // todo: add error message
}

export function* appointmentsWatcher() {
    yield takeLatest(fetchLastAppointmentSaga.toString(), fetchLastAppointmentWorker);

    yield takeLatest(cancelAppointmentSagaActions.name, cancelAppointmentWorker);
    yield takeLatest(cancelAppointmentSagaActions.start, cancelAppointmentStartWorker);
    yield takeLatest(cancelAppointmentSagaActions.success, cancelAppointmentSuccessWorker);
    yield takeLatest(cancelAppointmentSagaActions.fail, cancelAppointmentFailWorker);
}


import styled from "@emotion/styled";
import { Link } from "components/ui/Link";

export const ReplacePhoneAuth = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
`;

export const PhoneSpan = styled.span`
    display: block;
    font-weight: bold;
`;

export const ChangePhoneLink = styled(Link)`
    margin-top: 5px;
    color: ${({ theme }) => theme.pages.authorizationPage.changeNumberBtn.font.color};
`;


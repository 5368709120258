import { Button } from "components/ui/Button";
import { FloatingNotification } from "components/ui/FloatingNotification";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InvoiceStatus, SingleAppointmentState, cancelAppointmentSaga } from "stores/appointmentsSlice";
import { RootState } from "stores/store";
import {
    ContentWrapper,
    CopyLink,
    DoctorDefaultImage,
    DoctorImage,
    DoctorInfo,
    DoctorName,
    DoctorSpecialization,
    InfoWrapper,
    HeadWrapper,
    MeetingLinkWrapper,
    NotificationContainer,
    NotificationContent,
    NotificationIcon,
    DoctorInfoWrapper,
    ButtonTextMobileCorrection,
    AppointmentContainer,
    MenuWrapper,
    MenuFloatWrapper,
    MenuFloatItem,
    FormContainer,
    ButtonSubmitForm,
} from "./MyAppointments.styled";
import { ReactComponent as CalendarSvg } from "assets/images/calendar.svg";
import { ReactComponent as OnlineTypeSvg } from "assets/images/appointment-type-online.svg";
import { ReactComponent as OutsideTypeSvg } from "assets/images/appointment-type-outside.svg";
import { ReactComponent as InsideTypeSvg } from "assets/images/appointment-type-inside.svg";
import { ReactComponent as MoneySvg } from "assets/images/money.svg";
import { ReactComponent as LinkSvg } from "assets/images/link.svg";
import { ReactComponent as MoreVertSvg } from "assets/images/more-vert.svg";
import { AppointmentType, FetchStatus } from "entities/Enums";
import { BlockWrapper, Header1, Header2, HorizontalRule } from "assets/styles/global.styled";
import { formatDate, formatWeekday } from "utils/datetime";
import { useOutsideClick } from "hooks/useOutsideClick";
import { Modal } from "components/ui/Modal";
import { useForm } from "react-hook-form";

export const MyAppointments = (): React.ReactElement => {
    const dispatch = useDispatch();
    const menuRef = useRef<HTMLDivElement>(null);

    const { profile } = useSelector((state: RootState) => state.user);
    const { appointments, canceling } = useSelector((state: RootState) => state.appointments);

    const [lastAppointment, setLastAppointment] = useState<SingleAppointmentState>(appointments[0]);
    const [countClicks, setCountClicks] = useState(0);
    const [menuOpened, setMenuOpened] = useState(false);
    const [cancelModalOpened, setCancelModalOpened] = useState(false);

    useOutsideClick(menuRef, () => setMenuOpened(false), menuOpened);

    const { handleSubmit: handleSubmitCancelForm, watch, reset, control } = useForm({
        mode: "onChange",
    });

    useEffect(() => {
        setLastAppointment(appointments[0]);
    }, [appointments]);

    const handleClickOpenMeetingLink = () => {
        window.open(lastAppointment.doctor.meetingLink, "_blank");
    };

    const handleClickOpenPaymentLink = () => {
        window.open(lastAppointment.invoice.paymentUrl, "_self");
    };

    const handleClickCopyMeetingLink = () => {
        setCountClicks((prev) => prev + 1);
        navigator.clipboard.writeText(lastAppointment.doctor.meetingLink);
        setTimeout(() => {
            setCountClicks((prev) => prev - 1);
        }, 1000);
    };

    const handleClickCancelAppointment = () => {
        setCancelModalOpened(true);
        setMenuOpened(false)
    }

    const handleCloseCancelModal = () => {
        setCancelModalOpened(false);
    }

    const onSubmitCancelForm = () => {
        if (canceling.loading !== FetchStatus.Complete) {
            dispatch(
                cancelAppointmentSaga(lastAppointment.id)
            );
        }
    }

    return (
        <BlockWrapper>
            <Header1>Вы записаны на прием</Header1>
            <AppointmentContainer>
                <HeadWrapper>
                    <DoctorInfoWrapper>
                        {!!lastAppointment.doctor.image ? (
                            <DoctorImage src={lastAppointment.doctor.image}></DoctorImage>
                        ) : (
                            <DoctorDefaultImage />
                        )}
                        <DoctorInfo>
                            <DoctorName>{lastAppointment.doctor.name}</DoctorName>
                            <DoctorSpecialization>{lastAppointment.doctor.specialization}</DoctorSpecialization>
                        </DoctorInfo>
                    </DoctorInfoWrapper>

                    <InfoWrapper>
                        <CalendarSvg />
                        <div>
                            <p>{formatWeekday(lastAppointment.startDatetime)}</p>
                            <p>{formatDate(lastAppointment.startDatetime)}</p>
                        </div>
                    </InfoWrapper>

                    <InfoWrapper>
                        {lastAppointment.type === AppointmentType.Online ? (
                            <>
                                <OnlineTypeSvg />
                                <span>Онлайн прием</span>
                            </>
                        ) : lastAppointment.type === AppointmentType.Inside ? (
                            <>
                                <InsideTypeSvg />
                                <span>Прием в клинике</span>
                            </>
                        ) : lastAppointment.type === AppointmentType.Outside ? (
                            <>
                                <OutsideTypeSvg />
                                <span>Выезд на дом</span>
                            </>
                        ) : (
                            ""
                        )}
                    </InfoWrapper>

                    <InfoWrapper>
                        <MoneySvg />
                        <span>{lastAppointment.invoice.sum === 0 ? "Бесплатно" : lastAppointment.invoice.sum + " ₽"}</span>
                    </InfoWrapper>
                    
                    {!!lastAppointment.invoice.cancelable && (
                        <MenuWrapper ref={menuRef}>
                            <MoreVertSvg onClick={() => setMenuOpened(!menuOpened)}/>
                            <MenuFloatWrapper $opened={menuOpened}>
                                <MenuFloatItem onClick={handleClickCancelAppointment}>Отменить запись</MenuFloatItem>
                            </MenuFloatWrapper>
                            
                            {cancelModalOpened && (
                                <Modal onClose={handleCloseCancelModal}>
                                    <Header2>Отмена записи на прием</Header2>
                                    <div>
                                        {lastAppointment.invoice.sum > 0 && 'При отмене деньги вернуться на вашу карту. '}
                                        <span>Вы действительно хотите отменить запись?</span>
                                    </div>
                                    <FormContainer onSubmit={handleSubmitCancelForm(onSubmitCancelForm)}>
                                        <ButtonSubmitForm type="submit" loading={canceling.loading}>
                                            Отменить
                                        </ButtonSubmitForm>
                                    </FormContainer>
                                </Modal>
                            )}
                        </MenuWrapper>
                    )}
                </HeadWrapper>
                <HorizontalRule/>
                {lastAppointment.invoice.status === InvoiceStatus.PAID && (
                    <ContentWrapper>
                        <NotificationContainer>
                            <NotificationIcon />
                            <NotificationContent>
                                {lastAppointment.type === AppointmentType.Online ? (
                                    <>
                                        Просим вас подключаться по ссылке за 5 минут до начала приема и ожидать, когда врач примет ваше
                                        приглашение.
                                    </>
                                ) : lastAppointment.type === AppointmentType.Inside ? (
                                    <>
                                        Мы ожидаем вас в нашей клинике по адресу <b>{lastAppointment.doctor.address}</b>. Просим вас
                                        подходить за 15 минут до начала приема.
                                    </>
                                ) : lastAppointment.type === AppointmentType.Outside ? (
                                    <>
                                        Мы подъедем к вам по адрессу <b>{lastAppointment.patient.address}</b>. Ожидайте звонка на номер{" "}
                                        <b>{profile.data.phone}</b> для уточнения адреса.
                                    </>
                                ) : (
                                    ""
                                )}
                            </NotificationContent>
                        </NotificationContainer>

                        {lastAppointment.type === AppointmentType.Online && (
                            <MeetingLinkWrapper>
                                <Button onClick={handleClickOpenMeetingLink}>
                                    Открыть ссылку<ButtonTextMobileCorrection>&nbsp;на подключение</ButtonTextMobileCorrection>
                                </Button>
                                <CopyLink icon={<LinkSvg />} title="Скопировать ссылку" url={""} onClick={handleClickCopyMeetingLink} />
                                <FloatingNotification text={"Скопировано"} visibility={!!countClicks} />
                            </MeetingLinkWrapper>
                        )}
                    </ContentWrapper>
                )}

                {lastAppointment.invoice.status === InvoiceStatus.CREATED && (
                    <ContentWrapper>
                        <NotificationContainer $isError={true}>
                            <NotificationIcon />
                            <NotificationContent>
                                <b>Консультация забронирована, но не оплачена.</b> Просим вас оплатить консультацию в течение 10 минут, пока
                                действует бронь.
                            </NotificationContent>
                        </NotificationContainer>

                        <MeetingLinkWrapper>
                            <Button onClick={handleClickOpenPaymentLink}>Оплатить</Button>
                        </MeetingLinkWrapper>
                    </ContentWrapper>
                )}
            </AppointmentContainer>

            {/* {lastAppointment.type === AppointmentType.Online && 
        <AppointmentHelperText>
          Если у вас возникли вопросы по подключению, просим изучить <Link title={'инструкцию'} url={''} type={'outside'} target={'_blank'}/>.
        </AppointmentHelperText>
      } */}
        </BlockWrapper>
    );
};


import React, { useEffect, useMemo } from "react";
import { ThemeProvider, Global } from "@emotion/react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import ErrorBoundary from "./ErrorBoundary";
import { themeStyles } from "assets/styles/theme";
import { Layout } from "./common/Layout";
import { Main } from "./common/Main";
import { Authorization } from "./pages/Authorization";
import { GlobalStyles } from "assets/styles/global.styled";
import { DoctorList } from "./pages/DoctorList";
import { URL_AUTH, URL_MAIN_PAGE } from "constants/url";
import { MainWrapper } from "./wrappers/MainWrapper";
import { AuthWrapper } from "./wrappers/AuthWrapper";
import Cookies from "js-cookie";
import { TOKEN_NAME } from "constants/common";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfileSaga, setToken } from "stores/userSlice";
import { RootState } from "stores/store";
import { fetchConfigSaga, setApplicationStarted } from "stores/applicationSlice";
import { META_DESCRIPTION, META_TITLE, YM_CLIENT_COUNTER_ID, YM_COMMON_COUNTER_ID } from "constants/customInfo";
import { FetchStatus } from "entities/Enums";
import YaMetrika from "utils/yametrika";

export function App(): React.ReactElement {
    const dispatch = useDispatch();

    const {
        started,
        config: { loading: configLoading },
    } = useSelector((state: RootState) => state.application);
    const {
        token,
        profile: { data: profile, loading: profileLoading },
    } = useSelector((state: RootState) => state.user);

    const profileFilled = useMemo(() => {
        return Boolean(profile.registered && profile.tosAreAgreed && profile.firstName && profile.lastName);
    }, [token, profile]);

    useEffect(() => {
        const cookie_token = Cookies.get(TOKEN_NAME);
        if (cookie_token) {
            dispatch(setToken(cookie_token));
            dispatch(fetchUserProfileSaga());
        }
        dispatch(fetchConfigSaga());
    }, []);

    useEffect(() => {
        const profileLoaded = !token || profileLoading === FetchStatus.Complete;

        if (profileLoaded && configLoading === FetchStatus.Complete) {
            dispatch(setApplicationStarted(true));
        }
    }, [token, profileLoading, configLoading]);

    useEffect(() => {
        if (YM_COMMON_COUNTER_ID) new YaMetrika(YM_COMMON_COUNTER_ID).initialize(true);
        if (YM_CLIENT_COUNTER_ID) new YaMetrika(YM_CLIENT_COUNTER_ID).initialize(false);
    }, []);

    return (
        <ErrorBoundary>
            <Helmet>
                <title>{META_TITLE}</title>
                <meta name="description" content={META_DESCRIPTION} />
            </Helmet>
            <ThemeProvider theme={themeStyles}>
                <Global styles={GlobalStyles(themeStyles)} />
                <Layout>
                    {!!started && (
                        <Main>
                            <Routes>
                                <Route path="*" element={<Navigate to={URL_MAIN_PAGE} replace={true} />} />

                                <Route path={URL_AUTH} element={<AuthWrapper />}>
                                    <Route
                                        index
                                        element={<>{token && profileFilled ? <Navigate to={URL_MAIN_PAGE} /> : <Authorization />}</>}
                                    />
                                </Route>

                                <Route element={<MainWrapper />}>
                                    <Route
                                        path={URL_MAIN_PAGE}
                                        element={<>{!token || profileFilled ? <DoctorList /> : <Navigate to={URL_AUTH} />}</>}
                                    />
                                </Route>
                            </Routes>
                        </Main>
                    )}
                </Layout>
            </ThemeProvider>
        </ErrorBoundary>
    );
}


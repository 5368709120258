import styled from "@emotion/styled";
import { H1 } from "assets/styles/global.styled";
import { Button } from "../../ui/Button";

export const TitleForm = styled(H1)`
    text-align: center;
    margin: 0 0 15px;
`;

export const SubtitleForm = styled.p`
    text-align: center;
`;

export const ButtonSubmitForm = styled(Button)`
    width: 100%;
    margin-top: 30px;

    &:disabled {
        cursor: default;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-top: 20px;
    }
`;

export const ResponseContainerForm = styled.div`
    max-width: 466px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        max-width: 320px;
    }
`;

export const FormContainer = styled.form`
    margin-top: 40px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-top: 30px;
    }
`;

export const ErrorField = styled.p`
    margin: 10px 0;
    width: 100%;

    text-align: center;
    color: #ff4848;
`;


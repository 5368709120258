import React, { memo } from "react";
import { FetchStatus } from "entities/Enums";
import { ButtonGroupWrapper, GroupButton } from "./ButtonGroup.styled";

type ValueType = {
    id: number;
    value: string;
};

type ButtonGroupType = {
    values: ValueType[];
    activeValueId: number;
    styleType?: "horizontal" | "vertical";
    className?: string;
    disabled?: boolean;
    loading?: FetchStatus;
    onClick: (id: number) => void;
};

const ButtonGroupContainer: React.FC<ButtonGroupType> = (props) => {
    const { values, activeValueId, styleType = "horizontal", className, disabled = false, loading = false, onClick } = props;

    return (
        <ButtonGroupWrapper className={className} $styleType={styleType}>
            {values.map((x, i) => (
                <GroupButton
                    key={i}
                    $styleType={styleType}
                    typeStyled={x.id === activeValueId ? "primary" : "secondary"}
                    onClick={() => onClick(x.id)}
                >
                    {x.value}
                </GroupButton>
            ))}
        </ButtonGroupWrapper>
    );
};

export const ButtonGroup: React.FC<ButtonGroupType> = memo(ButtonGroupContainer);

import styled from "@emotion/styled";
import { Link } from "components/ui/Link";

export const LastNameContainer = styled.div`
    margin-top: 30px;
`;

export const ConfirmationContainer = styled.div`
    margin-top: 30px;

    label: {
        font-size: 14px;
    }
`;

export const HelperTextForm = styled.div`
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.pages.authorizationPage.helperText.font.color};
    font-size: ${({ theme }) => theme.pages.authorizationPage.helperText.font.size.desktop};
    margin-top: 30px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: ${({ theme }) => theme.pages.authorizationPage.helperText.font.size.mobile};
    }
`;

export const HelperTextPhone = styled.span`
    font-weight: bold;
    white-space: nowrap;
`;

export const ChangePhoneLink = styled(Link)`
    margin-top: 5px;
    color: ${({ theme }) => theme.pages.authorizationPage.changeNumberBtn.font.color};
`;


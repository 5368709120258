import { SelectItem } from "components/ui/Select/Select";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "stores/store";
import { SpecializationSelectWrapper } from "./SpecializationSelect.styled";

type BranchSelectType = {
    activeSpecializationSlug: string;
    onChange: (slug: string) => void;
};

const SpecializationSelectContainer: React.FC<BranchSelectType> = (props) => {
    const { activeSpecializationSlug, onChange } = props;
    const { specializations } = useSelector((state: RootState) => state.doctors);

    const specializationItems = useMemo(() => {
        return specializations.data.map<SelectItem>((x) => {
            return { id: x.slug, value: x.title };
        });
    }, [specializations]);

    const handleChangeSpecialization = (id?: number | string, value?: string) => {
        onChange(id ? (id as string) : "");
    };

    return (
        <SpecializationSelectWrapper
            label="Специализация"
            values={specializationItems}
            valueId={activeSpecializationSlug}
            onChange={handleChangeSpecialization}
        />
    );
};

export const SpecializationSelect: React.FC<BranchSelectType> = memo(SpecializationSelectContainer);


import axios, { AxiosRequestConfig } from "axios";
import { SagaIterator } from "redux-saga";
import { put } from "redux-saga/effects";
import Cookies from "js-cookie";

import { ActionWithOptionalPayloadType, HTTPMethodType, ResponseType } from "entities/Redux";
import { API_URL } from "constants/customInfo";
import { logout } from "stores/userSlice";
import { TOKEN_NAME } from "constants/common";
import { isPlainObject } from "utils/utils";
import { push } from "redux-first-history";
import { URL_AUTH } from "constants/url";

/** Создание Action */
export function createActionType(actionName: string): { [name: string]: string } {
    return {
        name: `${actionName}`,
        start: `${actionName}_start`,
        fail: `${actionName}_fail`,
        success: `${actionName}_success`,
        invalid: `${actionName}_invalid`, // 400 error
        server: `${actionName}_server`, // 500 error
    };
}

/** Http methods */
export const { GET, POST, PUT, DEL } = HTTPMethodType;

type ParamsType = { [key: string]: string | boolean | number | Blob; file: string | Blob };

export function createRequest(actionName: string, type: HTTPMethodType, path: string): any {
    if (!path && typeof path !== "string" && !/(^\/)([\S]*)/gi.test(path)) throw Error("Wrong api url path");

    const requestTypeList = [HTTPMethodType.GET, HTTPMethodType.POST, HTTPMethodType.PUT, HTTPMethodType.DEL];
    const requestType = requestTypeList.includes(type) ? type : HTTPMethodType.GET;
    const apiUrl = API_URL;

    const config: AxiosRequestConfig = {
        method: requestType,
        baseURL: apiUrl,
        url: path,
        withCredentials: true,
    };

    const token = Cookies.get(TOKEN_NAME);
    if (token) config.headers = { Authorization: `Token ${token}` };

    return function* requestSaga(requestParams: ParamsType, startActionPayload?: ParamsType): Generator {
        const startAction: ActionWithOptionalPayloadType = { type: `${actionName}_start` };

        if (isPlainObject(startActionPayload)) {
            startAction.payload = startActionPayload;
        }
        switch (requestType) {
            case HTTPMethodType.PUT: {
                if (requestParams && "file" in requestParams) {
                    config.headers = config.headers ?? {};
                    config.headers["Content-Type"] = "multipart/form-data";
                    const fileData = new window.FormData();
                    fileData.append("file", requestParams.file);
                    config.data = fileData;
                } else {
                    config.data = requestParams;
                }
                break;
            }
            case HTTPMethodType.POST:
                if (requestParams instanceof window.FormData) {
                    config.headers = config.headers ?? {};
                    config.headers["Content-Type"] = "multipart/form-data";
                }
                config.data = requestParams;
                break;

            default:
                config.params = requestParams;
                break;
        }

        yield put(startAction);

        try {
            return yield axios(config);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return error.response;
            } else {
                return null;
                // Just a stock error
            }
        }
    };
}

function* errorNetworkSaga(response: ResponseType, actionName: string) {
    yield put({ type: `${actionName}_fail` });
}

function* successSaga(response: ResponseType, params: any, actionName: string, options: Record<string, any>) {
    const { data } = response;

    yield put({
        type: `${actionName}_success`,
        payload: { data, options, params },
    });
}

function* failSaga(response: ResponseType, actionName: string): SagaIterator {
    const { data } = response;
    yield put({ type: `${actionName}_fail`, payload: data });
}

function* error401Saga(response: ResponseType): SagaIterator {
    yield put(logout());
    yield put(push(URL_AUTH)); // todo: redirect to previous page after authorization
}

function* error4xxSaga(response: ResponseType, actionName: string) {
    const { data } = response;

    if (data) {
        yield put({
            type: `${actionName}_invalid`,
            payload: isPlainObject(data) ? { ...data } : data,
        });
    }
}

function* error404Saga(response: ResponseType) {}

function* error5xxSaga(response: ResponseType, actionName: string) {
    const { data } = response;
    yield put({
        type: `${actionName}_server`,
        payload: isPlainObject(data) ? { ...data } : data,
    });
}

export const createCommonRequest = (actionName: string, type: HTTPMethodType, path: string): any => {
    const request = createRequest(actionName, type, path);

    return function* commonRequestSaga(params: any, options: any) {
        const response: ResponseType = yield request(params);

        if (!response) {
            yield errorNetworkSaga(response, actionName);
        } else {
            const { status } = response;

            if (status === 200) {
                //! if(status === 201) False
                yield successSaga(response, params, actionName, options);
            } else {
                yield failSaga(response, actionName);
            }

            if (status === 401) {
                yield error401Saga(response);
            } else if (status >= 400 && status < 500) {
                yield error4xxSaga(response, actionName);
            } else if (status >= 500) {
                yield error5xxSaga(response, actionName);
            }
        }

        return response;
    };
};


import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { FetchStatus } from "entities/Enums";
import { AuthorizationState, CheckOtpKeyType, SendPhoneType } from "./types/authorizationTypes";
import { LoadingState } from "entities/Redux";
import { UserProfileState } from "./types/userTypes";

const initialState: AuthorizationState = {
    phone: "",
    otpKey: "",
    loading: FetchStatus.None,
    error: "",
    fieldErrors: {},
    lastSendingCodeTimestamp: undefined,
};

export const authorizationSlice = createSlice({
    name: "authorization",
    initialState,
    reducers: {
        setPhone: (state, action: PayloadAction<string>) => {
            state.phone = action.payload;
        },
        setOtpKey: (state, action: PayloadAction<string>) => {
            state.otpKey = action.payload;
        },
        setLastSendingCodeTimestamp: (state, action: PayloadAction<number>) => {
            state.lastSendingCodeTimestamp = action.payload;
        },
        setAuthorizationLoadingState: (state, action: PayloadAction<LoadingState>) => {
            state.loading = action.payload.loading;
            state.error = action.payload.error ? action.payload.error : "";
        },
        clearAuthorizationState: () => {
            return initialState;
        },

        setFieldErrorMessage: (state, action: PayloadAction<TErrors>) => {
            state.fieldErrors = action.payload;
        },

        // saga reducers

        sendPhoneSaga: (state, action: PayloadAction<SendPhoneType>) => {},
        checkOtpKeySaga: (state, action: PayloadAction<CheckOtpKeyType>) => {},
        registerProfileSaga: (state, action: PayloadAction<UserProfileState>) => {},
    },
});

export const {
    setPhone,
    setOtpKey,
    setLastSendingCodeTimestamp,
    setAuthorizationLoadingState,
    clearAuthorizationState,
    setFieldErrorMessage,
} = authorizationSlice.actions;

export const { sendPhoneSaga, checkOtpKeySaga, registerProfileSaga } = authorizationSlice.actions;

export const authorizationReducer = authorizationSlice.reducer;


import { configureStore } from "@reduxjs/toolkit";
import { authorizationReducer } from "./authorizationSlice";
import { appointmentsReducer } from "./appointmentsSlice";
import { userReducer } from "./userSlice";
import { doctorsReducer } from "./doctorsSlice";
import createSagaMiddleware from "@redux-saga/core";
import { rootWatcher } from "saga";

import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";
import { applicationReducer } from "./applicationSlice";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ history: createBrowserHistory() });

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: {
        router: routerReducer,
        application: applicationReducer,
        authorization: authorizationReducer,
        appointments: appointmentsReducer,
        user: userReducer,
        doctors: doctorsReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware, routerMiddleware),
});

sagaMiddleware.run(rootWatcher);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const history = createReduxHistory(store);


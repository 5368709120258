import { Action, SliceCaseReducers, ValidateSliceCaseReducers } from "@reduxjs/toolkit";
import { FetchStatus } from "./Enums";

export enum HTTPMethodType {
    GET = "get",
    POST = "post",
    PUT = "put",
    DEL = "delete",
}

export type ResponseType = {
    status: number;
    data: any;
    errors: { [key: string]: string };
    message: string;
};

export interface ActionWithoutPayloadType extends Action {
    [key: string]: any;
}

export interface ActionWithPayloadType<T = any> extends Action {
    payload: T;
    [key: string]: any;
}

export interface ActionWithOptionalPayloadType<T = any> extends Action {
    payload?: T;
    [key: string]: any;
}

export interface FormLoadingState extends LoadingState {
    fieldErrors?: TErrors;
}

export interface LoadingState {
    loading: FetchStatus;
    error?: string;
}

export interface LoadingDataState<T = any> extends LoadingState {
    data: T;
}


import React, { memo } from "react";
import { Container } from "./ElementContainer.styled";

type ElementContainerType = {
    className?: string;
    children: React.ReactNode;
};

const ElementContainerContainer: React.FC<ElementContainerType> = (props) => {
    const { className, children } = props;

    return <Container className={className}>{children}</Container>;
};

export const ElementContainer: React.FC<ElementContainerType> = memo(ElementContainerContainer);


import { AppointmentType } from "entities/Enums";
import { DoctorListFilter, DoctorItemViewState, DoctorItemCacheState, ScheduleState } from "stores/types/doctorsTypes";
import { numberIsEmpty } from "utils/utils";

export class DoctorCacheListHelper {
    private _doctors: DoctorItemCacheState[] = [];

    constructor(doctors: DoctorItemCacheState[]) {
        this._doctors = doctors;
    }

    filter = (filter: DoctorListFilter) => {
        const { online, inside, outside, spec, branch, limit, offset } = filter;

        const appointmentType =
            (online && AppointmentType.Online) || (inside && AppointmentType.Inside) || (outside && AppointmentType.Outside);
        const matchSchedule = (schedule: ScheduleState) => {
            if (appointmentType !== schedule.appointmentType) return false;

            if (appointmentType === AppointmentType.Inside) {
                return (!numberIsEmpty(branch) && schedule.branch?.id === branch) || (numberIsEmpty(branch) && !schedule.branch);
            }

            return true;
        };

        const sliceEnd = limit ? (offset ? limit + offset : limit) : undefined;

        const filteredDoctors = this._doctors
            .filter((doctor) => {
                return spec ? doctor.specialization.slug === spec : true;
            })
            .filter((doctor) => doctor.schedules.some(matchSchedule))
            .slice(0, sliceEnd);

        return new DoctorCacheListHelper(filteredDoctors);
    };

    get = () => {
        return this._doctors;
    };

    getView = (filter: DoctorListFilter): DoctorItemViewState[] => {
        const { online, inside, outside, branch } = filter;

        const appointmentType =
            (online && AppointmentType.Online) || (inside && AppointmentType.Inside) || (outside && AppointmentType.Outside);
        const matchSchedule = (schedule: ScheduleState) => {
            if (appointmentType !== schedule.appointmentType) return false;

            if (appointmentType === AppointmentType.Inside) {
                return (!numberIsEmpty(branch) && schedule.branch?.id === branch) || (numberIsEmpty(branch) && !schedule.branch);
            }

            return true;
        };

        return this._doctors
            .filter((doctor) => doctor.schedules.some(matchSchedule))
            .map((doctor) => {
                const matchingSchedule = doctor.schedules.find(matchSchedule);
                return {
                    ...doctor,
                    schedule: matchingSchedule as ScheduleState,
                };
            });
    };
}


import styled from "@emotion/styled";
import { PageContainer, ResponseContainer } from "assets/styles/global.styled";
import { Link } from "components/ui/Link";

export const NavbarContainer = styled(PageContainer)`
    background-color: white;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.02);
    z-index: 1;
`;

export const NavbarResponseContainer = styled(ResponseContainer)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    max-height: 60px;
    padding: 20px 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
        height: 50px;
        max-height: 50px;
        padding: 10px 0;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        height: 40px;
        max-height: 40px;
        padding: 10px 0;
    }
`;

export const NavbarLink = styled(Link)`
    color: #3a3b41;

    svg {
        fill: #9d9faf;
    }

    &:hover {
        color: #2c41ff;

        svg {
            fill: #2c41ff;
        }
    }

    &:focus-visible {
        color: #2c41ff;

        svg {
            fill: #2c41ff;
        }
    }

    &:active,
    &.active {
        color: #2c41ff;

        svg {
            fill: #2c41ff;
        }
    }
`;

export const PhoneLink = styled(NavbarLink)``;

export const PhoneLinkText = styled.span`
    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        display: none;
    }
`;
export const NavbarRightAlign = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`;


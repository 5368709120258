import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import { RootState } from "stores/store";
import { MainFooter } from "./components/MainFooter";
import { MainNavbar } from "./components/MainNavbar";
import { MainWrapperContainer } from "./MainWrapper.styles";

export function MainWrapper(): React.ReactElement {
    const { started } = useSelector((state: RootState) => state.application);

    return (
        <MainWrapperContainer>
            {!!started && (
                <>
                    <MainNavbar />
                    <Outlet />
                    <MainFooter />
                </>
            )}
        </MainWrapperContainer>
    );
}


import React from "react";
import { Controller } from "react-hook-form";
import { ControllerType } from "entities/Forms";
import { Checkbox } from "components/ui/Checkbox";
import { Link } from "components/ui/Link";
import { FetchStatus } from "entities/Enums";
import { PRIVACY_POLICY_LINK } from "constants/customInfo";

type ControllerCheckboxProps = Omit<ControllerType, "autoFocus"> & {
    defaultValue?: boolean;
    disabled?: boolean;
};

export const ControllerCheckbox: React.FC<ControllerCheckboxProps> = (props) => {
    const { name, control, defaultValue = false, loading, disabled = false, error } = props;

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, value } }) => {
                return (
                    <Checkbox
                        label="Даю согласие на обработку своих персональных данных."
                        value={value}
                        disabled={loading === FetchStatus.Fetching || disabled}
                        onChange={(e) => onChange(Number(e))}
                        error={error}
                    >
                        <>
                            Даю согласие на обработку своих <Link title="персональных данных" url={PRIVACY_POLICY_LINK} />.
                        </>
                    </Checkbox>
                );
            }}
        />
    );
};


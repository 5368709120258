import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppointmentType, FetchStatus } from "entities/Enums";
import { LoadingState } from "entities/Redux";

export enum InvoiceStatus {
    CREATED = "created",
    PAID = "paid",
    EXPIRED = "expired",
}

export interface SingleAppointmentState {
    id: number;
    type: AppointmentType;
    startDatetime: number;
    doctor: {
        name: string;
        specialization: string;
        image?: string;
        meetingLink: string;
        address: string;
    };
    patient: {
        address: string;
    };
    invoice: {
        sum: number;
        status: InvoiceStatus;
        invoiceId: string;
        paymentUrl: string;
        cancelable: boolean;
    };
}

export interface AppointmentsState {
    appointments: SingleAppointmentState[];
    canceling: LoadingState;
}

const initialState: AppointmentsState = {
    appointments: [],
    canceling: {
        loading: FetchStatus.None,
        error: ''
    }
};

export const appointmentsSlice = createSlice({
    name: "appointments",
    initialState,
    reducers: {
        setAppointments: (state, action: PayloadAction<SingleAppointmentState[]>) => {
            state.appointments = action.payload;
        },
        setCancelingLoadingState: (state, action: PayloadAction<LoadingState>) => {
            state.canceling.loading = action.payload.loading;
            state.canceling.error = !!action.payload.error ? action.payload.error : "";
        },

        // saga reducers

        fetchLastAppointmentSaga: () => {},
        cancelAppointmentSaga: (state, action: PayloadAction<number>) => {}
    },
});

export const { setAppointments, setCancelingLoadingState } = appointmentsSlice.actions;

export const { fetchLastAppointmentSaga, cancelAppointmentSaga } = appointmentsSlice.actions;

export const appointmentsReducer = appointmentsSlice.reducer;


import styled from "@emotion/styled";

type NotificationContainerProps = {
    $visibility: boolean;
};

export const NotificationContainer = styled.div<NotificationContainerProps>`
    position: fixed;
    bottom: 30px;
    padding: 20px 25px;
    background-color: white;
    border-radius: 10px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
    transition: ${(props) => props.theme.global.transition};
    ${({ $visibility }) => (!$visibility ? "visibility: hidden; opacity: 0;" : "")}
`;

export const NotificationText = styled.div`
    color: #2c41ff;
    font-weight: bold;
`;


import styled from "@emotion/styled";

export const LayoutWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const LayoutMaxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
`;


import styled from "@emotion/styled";
import { Select } from "components/ui/Select";

export const SpecializationSelectWrapper = styled(Select)`
    font-size: 18px;
    max-width: 600px;
    margin-top: 20px;
    margin-bottom: 40px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: 16px;
    }

    input {
        height: 56px;
    }
`;


import React, { memo, useEffect } from "react";
import { ButtonSubmitForm, FormContainer, ResponseContainerForm, SubtitleForm, TitleForm, ErrorField } from "../Forms.styled";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { FormStateType } from "entities/Forms";
import { RootState } from "stores/store";
import { checkOtpKeySaga, clearAuthorizationState, setAuthorizationLoadingState } from "stores/authorizationSlice";
import { ControllerOtp } from "../controllers/ControllerOtp";
import { BtnRepeatSmsCode } from "components/pages/Authorization/components/BtnRepeatSmsCode";
import { ChangePhoneLink, PhoneSpan, ReplacePhoneAuth } from "./FormSendCode.styled";
import { FetchStatus } from "entities/Enums";

import { setFieldErrorMessage } from "stores/authorizationSlice";

const FormSendCodeContainer = () => {
    const dispatch = useDispatch();
    const { phone, otpKey, loading, error, fieldErrors } = useSelector((state: RootState) => state.authorization);

    const { handleSubmit, watch, reset, control } = useForm<Pick<FormStateType, "code">>();

    const onSubmit = (data: Pick<FormStateType, "code">) => {
        dispatch(setFieldErrorMessage({}));
        dispatch(checkOtpKeySaga({ otp: data.code, key: otpKey }));
    };

    useEffect(() => {
        dispatch(setAuthorizationLoadingState({ loading: FetchStatus.None }));
    }, []);

    const handleClickChangePhoneBtn = () => {
        dispatch(clearAuthorizationState());
    };

    return (
        <ResponseContainerForm>
            <TitleForm>Подтверждение номера</TitleForm>
            <SubtitleForm>Вам поступит звонок на номер</SubtitleForm>

            <ReplacePhoneAuth>
                <PhoneSpan>{phone}</PhoneSpan>
                <ChangePhoneLink title="Изменить" url="" onClick={handleClickChangePhoneBtn} />
            </ReplacePhoneAuth>

            <FormContainer onSubmit={handleSubmit(onSubmit)}>
                <ControllerOtp name="code" control={control} loading={loading} error={fieldErrors?.otp} />
                <ErrorField>{error}</ErrorField>

                <ButtonSubmitForm type="submit" loading={loading}>
                    Продолжить
                </ButtonSubmitForm>
            </FormContainer>

            <BtnRepeatSmsCode />
        </ResponseContainerForm>
    );
};

export const FormSendCode = memo(FormSendCodeContainer);


import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ElementContainer } from "components/ui/ElementContainer";

export const DoctorItemSkeletonContainer = styled(ElementContainer)`
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 30px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletL}) {
        gap: 20px;
        margin-bottom: 20px;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

export const DoctorInfoSkeletonWrapper = styled.div`
    display: flex;
    gap: 25px;
    align-items: center;
    min-width: 400px;
    align-items: flex-start;
    flex: 1;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        min-width: auto;
    }
`;

export const HorizontalRule = styled.hr`
    display: none;
    margin-top: 0;
    margin-bottom: 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletL}) {
        display: block;
    }
`;

export const ScheduleSkeletonWrapper = styled.div`
    flex: 1;
`;

export const DoctorImageSkeleton = styled(Skeleton)`
    width: 155px;
    height: 155px;
    min-width: 155px;
    border-radius: 50%;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
        width: 75px;
        height: 75px;
        min-width: 75px;
    }
`;

export const TextLineSkeletonBlock = styled.div`
    width: 100%;

    span {
        margin-bottom: 10px;
    }
`;

export const TextLineSkeleton = styled(Skeleton)``;


import styled from "@emotion/styled";
import { Link } from "components/ui/Link";

export const BtnRepeatContainerAuth = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-top: 20px;
    }
`;

export const HelperTextRepeatSmsCodeAuth = styled.span``;

export const BtnRepeatSmsCodeAuth = styled(Link)`
    font-weight: bold;
`;


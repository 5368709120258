import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { LoadingState } from "entities/Redux";
import { DoctorFiltrationType, FetchStatus } from "entities/Enums";
import { ApplicationState, ConfigState } from "./types/applicationTypes";

const initialState: ApplicationState = {
    started: false,
    config: {
        data: {
            enabledAppointments: {
                online: false,
                inside: false,
                outside: false,
            },
            doctorsFiltrationType: DoctorFiltrationType.Back,
            doctorsPageSize: 0,
        },
        loading: FetchStatus.None,
        error: undefined,
    },
};

export const applicationSlice = createSlice({
    name: "application",
    initialState,
    reducers: {
        setApplicationStarted: (state, action: PayloadAction<boolean>) => {
            state.started = action.payload;
        },
        setConfig: (state, action: PayloadAction<ConfigState>) => {
            state.config.data = { ...action.payload };
        },
        setConfigLoadingState: (state, action: PayloadAction<LoadingState>) => {
            state.config.loading = action.payload.loading;
            state.config.error = !!action.payload.error ? action.payload.error : "";
        },

        // saga reducers

        fetchConfigSaga: (state) => {},
    },
});

export const { setApplicationStarted, setConfig, setConfigLoadingState } = applicationSlice.actions;

export const { fetchConfigSaga } = applicationSlice.actions;

export const applicationReducer = applicationSlice.reducer;

